import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import MainView from "views/Main";
import WelcomeView from "views/Welcome";

import DashboardView from "views/Dashboard";
import OrderView from "views/Order";
import AddressesView from "views/Addresses";
import BillingView from "views/Billing";

import ProfileView from "views/Profile";
const routes = [{
		path: "/welcome",
		component: WelcomeView
	},
	{
		path: "/",
		component: MainView,
		children: [{
				path: "",
				component: DashboardView
			},
			{
				path: "/order",
				component: OrderView
			},
			{
				path: "/addresses",
				component: AddressesView
			},
			{
				path: "/profile",
				component: ProfileView
			},
			
			{
				path: "/billing",
				component: BillingView
			}
		]
	}
];

const router = new VueRouter({
	routes,
	mode: "history"
});

export default router;