<template lang="html">
<section class="new-card"><div class="container">
	<div class="dialog">
		<card :stripe="stripe" @change="change" :options="options"ref="stripeCard"></card>
		<div class="button" :class="{ active: complete && !processing }" @click="submit">{{ processing ? "Processing..." : "Add new card" }}</div>
	</div>

	<div class="background" @click="close"></div>
</div></section>
</template>

<script>
import { Card, instance } from "vue-stripe-elements";

export default {
	components: {
		Card
	},

	data() {
		return {
			processing: false, // Whether processing the add card action
			complete: false,

			stripe: STRIPE_KEY,
			options: {
				hidePostalCode: true
			}
		}
	},

	methods: {
		close() {
			this.$emit("close");
		},

		submit() {
			if (this.complete) {
				this.processing = true;


let se = this.$refs.stripeCard.$refs.element;
instance.createPaymentMethod('card', se._element).then((paymentMethod, error) => {
	if (error) {
		console.error("Stripe Payment Method Error", error);
	} else {
	
						this.$api.post("/user/card", paymentMethod, { redirect: false }).then(res => {
							this.processing = false;

							if (res.status >= 200 && res.status < 300) this.close();
							else {
								window.alert(res.data);
							
							}
						});
					
				
	}
});
		
			}
		},

		change(event) {
			this.complete = event.complete;
		}
	}
}
</script>

<style lang="less" scoped>
section.new-card {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	overflow: scroll;
	z-index: 999;

	.container {
		display: flex;
		align-items: center;
		justify-content: center;

		position: absolute;
		left: 0;
		top: 0;
		padding: 20px;
		box-sizing: border-box;
		min-width: 100%;
		min-height: 100%;
	}

	.background {
		background-color: rgba(0, 0, 0, 0.5);

		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
	}

	.dialog {
		background-color: #FFF;
		padding: 30px;
		z-index: 11;
		border-radius: 4px;
		box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);

		display: flex;
		flex-direction: column;

		min-width: 400px;

		.button {
			padding: 14px 20px;
			background-color: #CCC;
			color: #FFF;
			margin-top: 20px;
			text-align: center;
			cursor: default;
			border-radius: 4px;
			font-size: 14px;

			&.active {
				background-color: #01BAD4;
				cursor: pointer;
			}
		}
	}
}
</style>
