<template lang="html">
	<section class="preferences">
		<div class="">
			<div class="header">Additonal order preferences</div>

			<loader v-if="loading" name="your preferences"></loader>
			<div class="preferences" v-else>
				<div class="header">Detergent</div>
				<div class="flex">
					<div class="option "  :class="[detergent.id == selectedDetergent.id ? 'selected' : '',detergent.disabled == 'true' ? 'disabled' : '']"
						v-for="detergent in preferences.detergent" @click="setPreferences(detergent)">
						<div class="name">{{ detergent.name  }}</div>
						<div class="price">{{ detergent.price | formatPrice }}</div>
					</div></div>
					<div class="header">Softener</div>
					<div class="flex">
					<div class="option" :class="[softener.id == selectedSoftener.id ? 'selected' : '',softener.disabled == 'true' ? 'disabled' : '']"
						v-for="softener in preferences.softener" @click="setPreferences(softener)">
						<div class="name">{{ softener.name }}</div>
						
						<div class="desc">{{ softener.Description }}</div>
						<div class="price">{{ softener.price | formatPrice }}</div>
					</div></div>
						<div class="header">Stain Removal</div>
					<div class="flex">
					<div class="option" :class="[stain.id == selectedStain.id ? 'selected' : '', stain.disabled == 'true' ? 'disabled' : '']"
						v-for="stain in preferences.stain" @click="setPreferences(stain)">
						<div class="name">{{ stain.name }}</div>
						
						<div class="desc">{{ stain.Description }}</div>
						<div class="price">{{ stain.price | formatPrice }}</div>
					</div></div>
					<div class="header">Drying</div>
					<div class="flex">
					<div class="option" :class="drying.id == selectedDrying.id ? 'selected' : ''"
						v-for="drying in preferences.drying" @click="setPreferences(drying)">
						<div class="name">{{ drying.name  }}</div>
						<div class="price">{{ drying.price | formatPrice }}</div>
					</div></div>
						<div class="header">Washing</div>
						<div class="flex">
					<div class="option" :class="washing.id == selectedWashing.id ? 'selected' : ''"
						v-for="washing in preferences.washing  " @click="setPreferences(washing)">
						<div class="name">{{ washing.name }}</div>
						<div class="desc">{{ washing.description }}</div>
						<div class="price">{{ washing.price | formatPrice }}</div>
					</div></div>

			</div>
			<div class="header">Preferences Total</div>
			<div style="text-align:center;">Preferences Cost:  <span class="price2">{{totalCost | formatPrice}}</span></div>
<div @click="updatePreferencesconfirm()" class="save">SAVE ORDER PREFERENCES</div>

		</div>
	</section>
</template>

<script>
import Vue from "vue";
export default {
	components: {

	},

	data() {
		return {

			loading: true, // Whether loading AJAX data
			preferences: null,
			totalCost:0,
			selectedDetergent: {
			id: "",
			name: "",
			description: "",
			price: 0,
			type: "",
			disabled:""	
			},
			selectedDrying: {
			id: "",
			name: "",
			description: "",
			price: 0,
			type: "",
			type: "",
			disabled:""		
			},
			selectedSoftener: {
				id: "",
				name: "",
				description: "",
				price: 0,
				type: "",
				disabled: ""
			},
				selectedStain: {
				id: "",
				name: "",
				description: "",
				price: 0,
				type: "",
				disabled: ""
			},
			selectedWashing: {
			id: "",
			name: "",
			description: "",
			price: 0,
			type: "",
			disabled:""		
			},
			

		}
	},

	async created() {
		try {
			const preferencesResponse = await this.$api.get("/preferences/all");
			this.preferences = preferencesResponse.data;
			this.loading = false;

			const userPreferencesResponse = await this.$api.get("/user/preferences");
			const userPreferences = userPreferencesResponse.data[0];

			this.selectedDetergent.id = userPreferences.detergent_id;
			this.selectedDrying.id = userPreferences.drying_id;
			this.selectedSoftener.id = userPreferences.softener_id;
			this.selectedStain.id = userPreferences.stain_id;
			this.selectedWashing.id = userPreferences.washing_id;

			let matchedID;

			matchedID = this.preferences.detergent.filter(item => item.id.indexOf(userPreferences.detergent_id) !== -1);
			this.selectedDetergent.price = matchedID[0].price;
			this.selectedDetergent.disabled = matchedID[0].disabled;

			matchedID = this.preferences.drying.filter(item => item.id.indexOf(userPreferences.drying_id) !== -1);
			this.selectedDrying.price = matchedID[0].price;
			this.selectedDrying.disabled = matchedID[0].disabled;

			matchedID = this.preferences.softener.filter(item => item.id.indexOf(userPreferences.softener_id) !== -1);
			this.selectedSoftener.price = matchedID[0].price;
			this.selectedSoftener.disabled = matchedID[0].disabled;

			matchedID = this.preferences.washing.filter(item => item.id.indexOf(userPreferences.washing_id) !== -1);
			this.selectedWashing.price = matchedID[0].price;
			this.selectedWashing.disabled = matchedID[0].disabled;

			matchedID = this.preferences.stain.filter(item => item.id.indexOf(userPreferences.stain_id) !== -1);
			this.selectedStain.price = matchedID[0].price;
			this.selectedStain.disabled = matchedID[0].disabled;

			this.PreferencesCost();
		} catch (error) {
			console.error(error);
		}
	},
	filters: {
		formatPrice: price => "£" + price.toFixed(2)
	},

	methods: {

		PreferencesCost(){

	this.totalCost = this.selectedDetergent.price+this.selectedDrying.price+this.selectedSoftener.price+this.selectedWashing.price + this.selectedStain.price

},
	
	updatePreferences(){
  var PreferencesToUpdate = {
			detergent_id: this.selectedDetergent.id,
			drying_id: this.selectedDrying.id,
		  softener_id: this.selectedSoftener.id,
			stain_id: this.selectedStain.id,
			washing_id: this.selectedWashing.id,
			cost: this.totalCost,
 },


PreferencesToUpdateJSON =  JSON.stringify(PreferencesToUpdate)

	 this.$api.post("/user/updatepreferences", PreferencesToUpdate).then(res => {

				if(res.status == '200'){

             
            }
              else{
                window.alert("Something went wrong, please try again");
            }
				
				
				
				});

				

	},
	updatePreferencesconfirm(){
  var PreferencesToUpdate = {
			detergent_id: this.selectedDetergent.id,
			drying_id: this.selectedDrying.id,
			stain_id: this.selectedStain.id,
			softener_id: this.selectedSoftener.id,
			washing_id: this.selectedWashing.id,
			cost: this.totalCost,
 },


PreferencesToUpdateJSON =  JSON.stringify(PreferencesToUpdate)

	 this.$api.post("/user/updatepreferences", PreferencesToUpdate).then(res => {

				if(res.status == '200'){

             window.alert("Preferences Saved");
            }
              else{
                window.alert("Something went wrong, please try again");
            }
				
				
				
				});

				

	},
	setPreferences(setSelected) {
		
		//do not update if disabled
		if(setSelected.disabled == 'true'){
			return
		}
		
			if (setSelected.type == "detergent")
			{
				this.selectedDetergent.id = setSelected.id
				const MatchedID = this.preferences.detergent.filter(item => item.id.indexOf(setSelected.id) !== -1);
				this.selectedDetergent.price = MatchedID[0].price
				this.updatePreferences()
				this.PreferencesCost()
				
			}
				if (setSelected.type == "drying")
			{
				this.selectedDrying.id = setSelected.id
				const MatchedID = this.preferences.drying.filter(item => item.id.indexOf(setSelected.id) !== -1);
				this.selectedDrying.price = MatchedID[0].price
				this.updatePreferences()
				this.PreferencesCost()

			}
				if (setSelected.type == "softener")
			{
				this.selectedSoftener.id = setSelected.id
				const MatchedID = this.preferences.softener.filter(item => item.id.indexOf(setSelected.id) !== -1);
				this.selectedSoftener.price = MatchedID[0].price
				this.updatePreferences()
				this.PreferencesCost()

				}
					if (setSelected.type == "stain") {
				this.selectedStain.id = setSelected.id
				const MatchedID = this.preferences.stain.filter(item => item.id.indexOf(setSelected.id) !== -1);
				this.selectedStain.price = MatchedID[0].price
				this.updatePreferences()
				this.PreferencesCost()

			}
				if (setSelected.type == "washing")
			{
				this.selectedWashing.id = setSelected.id
				const MatchedID = this.preferences.washing.filter(item => item.id.indexOf(setSelected.id) !== -1);
				this.selectedWashing.price = MatchedID[0].price
				this.updatePreferences()
				this.PreferencesCost()

			}
		
		
		},
		close() {
			this.showNewAddress = false;
			this.reload();
		}
	}
}
</script>
<style lang="less">@mobile: ~'(max-width: 900px)';
section.preferences {
	
	

		.title {
			color: #01BAD4;
		}

		cursor: default;
		touch-action: auto;
	}
.save{
    border: solid 1px #01BAD4;
    border-radius: 4px;
    padding: 14px;
    color: white;
    background: #01BAD4;
    text-align: center;
    cursor: pointer;
    font-size: 18px;
    max-width: 1070px;
    margin: auto;
    margin-top: 20px;
    flex-grow: 1;
    display: block;
    flex-direction: column;
    align-items: center;

}
	.header {
		font-size: 20px;
		padding-bottom: 10px;
		border-bottom: solid 1px #CCC;
		margin-top: 30px;
		margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.desc {
		font-size: 14px;
		color: #888;
		max-width: 300px;
		margin: auto;
		padding: 10px;
	}

	.price {
		font-size: 13px;
		margin-top: 4px;
		color: #01BAD4;
	}
		.price2 {
		font-size: 16px;
		margin-top: 4px;
		color: #01BAD4;
	}
.flex{display: flex;flex-wrap:wrap;}
	.option {
		border: solid 1px #DDD;
		border-radius: 4px;
		padding: 14px;
		margin: 5px;
		text-align: center;
		cursor: pointer;
		font-size: 18px;
		-webkit-transition: border 100ms ease-out;
		-moz-transition: border 100ms ease-out;
		-o-transition: border 100ms ease-out;
		
	
		flex-direction: column;
		align-items: center;
		
	}
.selected {
		border: solid 1px #01BAD4;

}
.disabled {
			opacity: 0.6;
			border: solid 1px #888;

			touch-action: default;
			cursor: default;
		}
@media @mobile {
.option{
	flex-direction: row;
	width:100%;
}

}
</style>
