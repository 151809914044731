<template lang="html">

<v-row class="pa-2" justify="center">
<link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet">
    <v-col align="center" class="pa-6" md="6">
<section class="calculator"><div class="container">
	<div class="dialog">
		<div class="header">Laundry calculator</div>
		<div class="item" v-for="(item, index) in calcitems">
		<div class="wrapper-buttons">	<div class="buttons"><v-btn @click="removeitem(index)" text icon >
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19,13H5V11H19V13Z" />
</svg>
            </v-btn>
			<div class="accentbox">{{ item.ammount }}</div>
		
		
				<v-btn @click="additem(index)" text icon >
             <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
</svg>
            </v-btn></div>	
			<div class="text">{{ item.name }}</div>
			<div class="accentbox right">{{ item.ammount * item.weight | FormatWeight }}</div>
		</div></div>
<div class="item">
		
			<div class="text total">Total: </div>
			<div class="accentbox right">{{ this.total | FormatWeight }}</div>
		</div>
		<div class="button" @click="close">Close window</div>
	</div>

	<div class="background" @click="close"></div>
</div></section>
</v-col>
</v-row>
</template>

<script>


export default {
	methods: {
		close() {
			this.$emit("close");
		},
		
			additem(index){
				
				this.calcitems[index].ammount = this.calcitems[index].ammount + 1
				this.total = this.total + this.calcitems[index].weight
              
		},
			removeitem(index){
			if(this.calcitems[index].ammount >= 1){
				this.calcitems[index].ammount = this.calcitems[index].ammount - 1
				this.total = this.total -  this.calcitems[index].weight
              }
              
		}
	},

data() {
		return {
			total: 0, // The overall category
			calcitems: [
				{ name: "Thick Towel", weight: 0.95, ammount: 0 },
				{ name: "Duvet Cover", weight: 0.80, ammount: 0 },
				{ name: "Pillow Case", weight: 0.10, ammount: 0 },
				{ name: "T-Shirt", weight: 0.15, ammount: 0 },
				{ name: "Women's Delicates", weight: 0.05, ammount: 0 },
				{ name: "Men's Boxers", weight: 0.07, ammount: 0 },
				{ name: "Pair of Socks", weight: 0.05, ammount: 0 },
				{ name: "Men's Shirts", weight: 0.3, ammount: 0 },
				{ name: "Hoodie", weight: 0.6, ammount: 0 },
				{ name: "Jacket", weight: 0.75, ammount: 0 },
				{ name: "Jumper/Cardigan", weight: 0.35, ammount: 0 },
				{ name: "Pair of Shorts", weight: 0.25, ammount: 0 },
				{ name: "Jeans", weight: 0.7, ammount: 0 },
				{ name: "Tracksuit Bottoms", weight: 0.4, ammount: 0 },
				{ name: "Pyjama Bottoms", weight: 0.2, ammount: 0 },


			],
				}

		},
		filters: {
		FormatWeight: weight => weight.toFixed(2) + " Kg" 
	}
}
</script>

<style lang="less" scoped>
section.calculator {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	overflow: scroll;
	z-index: 999;

	.header{margin-top:0px!important;}
.dialog{
width:60%;

}
.buttons{display: inline-block;}
.total{ padding: 15px;   height: 55px;}
	.container {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 0;
		top: 0;
		padding: 20px;
		box-sizing: border-box;
		min-width: 100%;
		min-height: 100%;
	}
	.right{float:right;margin-right: 10px}
	.accentbox{
font-size: 16px;
    margin-top: 3px;
	color: #01BAD4;
	    border-radius: 4px;
padding: 14px;
    padding-bottom: 10px;
	    display: inline-block;
	    border: solid 1px #DDD;
	}
.text{    display: inline-block;font-size: 1.0rem;}


		.item {
			border: solid 1px #DDD;
			border-radius: 4px;
			height: 55px;
			margin: 5px;
			text-align: left;
	
			font-size: 18px;
   			 width: 100%;
			flex-grow: 1;
			align-items: center;
		}
	.background {
		background-color: rgba(0, 0, 0, 0.5);

		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
	}

	.dialog {
		background-color: #FFF;
		padding: 30px;
		z-index: 11;
		border-radius: 4px;
		box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
		line-height: 1.3rem;
	}

	.button {
		border: solid 1px #DDD;
		border-radius: 4px;
		padding: 10px 20px;
		margin-top: 15px;
		text-align: center;
		cursor: pointer;

		&:hover { background-color: #FAFAFA; }
	}
}

.text{  font-size: 1.0rem;}
@media only screen and (max-width: 800px){
	.accentbox{

    margin-top: 10px!important;
	
	}
.dialog{
    width:100%!important;
    padding:10px!important;
.accentbox{padding: 5px!important;font-size: 0.8rem!important;}
.text{  font-size: 0.8rem!important;}
}

}
@media only screen and (max-width: 350px){

.text{  font-size: 0.8rem;overflow: scroll;
    max-width: 80px;
   
    white-space: nowrap;}

}
</style>
