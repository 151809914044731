<template lang="html">
<section v-touch="{ left: () => stepforward(), right: () => stepback(), }" class="order"><div class="wrapper-full">
	<!-- The left hand side primary order section -->
	<div class="primary" ref="primary">
	
			<div v-touch="{ left: () => stepforward(), right: () => stepback(), }" v-if="category !== 3 && orderstep !== 15" class="header">You have {{ basket.length }} items in basket<span  class="link" @click="viewbasket"> <span class="badge">{{ basket.length }}</span> <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M5.5,21C4.72,21 4.04,20.55 3.71,19.9V19.9L1.1,10.44L1,10A1,1 0 0,1 2,9H6.58L11.18,2.43C11.36,2.17 11.66,2 12,2C12.34,2 12.65,2.17 12.83,2.44L17.42,9H22A1,1 0 0,1 23,10L22.96,10.29L20.29,19.9C19.96,20.55 19.28,21 18.5,21H5.5M12,4.74L9,9H15L12,4.74M12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17A2,2 0 0,0 14,15A2,2 0 0,0 12,13Z" />
</svg></span></div>
			<div v-show="category !== 3 && orderstep !== 15" v-if="orderstep > 0" class="header">Step {{ orderstep }} out of 9</div>
		 <v-stepper v-if="orderstep !== 15" class="stepshide" light alt-labels :value="orderstep">
    <v-stepper-header  v-touch="{ left: () => stepforward(), right: () => stepback(), }" v-show="category !== 3">
		 <v-stepper-step step="0" ></v-stepper-step>

      <v-divider></v-divider>
      <v-stepper-step step="1"></v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="2"></v-stepper-step>

      <v-divider></v-divider>
	   <v-stepper-step step="3"></v-stepper-step>

      <v-divider></v-divider>
	   <v-stepper-step step="4"></v-stepper-step>

      <v-divider></v-divider>
	   <v-stepper-step step="5"></v-stepper-step>

      <v-divider></v-divider>
	   <v-stepper-step step="6"></v-stepper-step>

      <v-divider></v-divider>
	   <v-stepper-step step="7"></v-stepper-step>

      <v-divider></v-divider>
	   <v-stepper-step step="8"></v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="9"></v-stepper-step>

    </v-stepper-header>
  </v-stepper>
  <div class="messages">
			<div class="meta">
				<div  class="message " v-if="message.showing" >{{ message.text }}</div>
			
			</div>
			</div>
		<div class="lock" v-if="processing"></div>
		 <transition name="slide-fade">
			  

  </transition>
		  <transition name="slide-fade">
			  
		<category-view   :class="{ redborder: redborder }" v-model="category" v-if="category !== 3 && orderstep == 0" ></category-view>
  </transition>
    <transition name="slide-fade">
		<standalone-view   :class="{ redborder: redborder }"  v-if="category === 0 && orderstep == 1" v-model="standalone"></standalone-view>
		  </transition>
    <transition name="slide-fade">
		<express-view  :class="{ redborder: redborder }" v-if="category === 5 && orderstep == 1" v-model="express"></express-view>
		 </transition>
    <transition name="slide-fade">
		<house-view  :class="{ redborder: redborder }" v-if="category === 1 && orderstep == 1" v-model="house"></house-view>
		  </transition>
    <transition name="slide-fade">
		<bundle-view  :class="{ redborder: redborder }" v-if="category === 2 && orderstep == 1" v-model="bundle"></bundle-view>
		</transition>
    <transition name="slide-fade">
		<redeem-view  v-if="category === 3 && orderstep == 1" v-model="redeem"></redeem-view>
		</transition>
    <transition name="slide-fade">
		<custom-view   :class="{ redborder: redborder }" v-if="category === 4 && orderstep == 1" v-model="custom"></custom-view>
		</transition>
    <transition name="slide-fade">
		<kids-view  v-show ="orderstep == 2" v-if="orderstep == 2 && category == 0 || category === 5 && category !== null" v-model="kidsclothes"></kids-view>
		</transition>
    <transition name="slide-fade">
		<ownbag-view  v-show ="orderstep == 2" v-if="category == 1 || category == 0 || category === 5" v-model="ownBag"></ownbag-view>
		</transition>
    <transition name="slide-fade">
		<slots-view @touchstart.native.stop
    @touchmove.native.stop
    @touchend.native.stop :class="{ redborder: redborder }" v-show ="orderstep == 3" v-if="orderstep == 3 && category === 0 || category === 1 || category === 5 || category === 3" v-model="slot" :user="user" :isUpgraded="isUpgraded" :limit="slotLimit"></slots-view>
		</transition>
    <transition name="slide-fade">
		<address-view  :class="{ redborder: redborder }" v-show ="orderstep == 4" v-if="orderstep == 4 && category === 0 || category === 5 || category === 1 || category === 3" v-model="address"></address-view>
		</transition>
    <transition name="slide-fade">
		<voucher-view   v-show ="orderstep == 5" v-if="orderstep == 5 && category === 0 || category === 5 && category !== null" @applyVoucher="applyVoucher"></voucher-view>
		</transition>
    <transition name="slide-fade">
		<payment-view  :class="{ redborder: redborder }" v-if="orderstep == 6 && category !== null && category !== 3" v-model="card"></payment-view>
		</transition>
    <transition name="slide-fade">
		<terms-view  :class="{ redborder: redborder }" v-if="orderstep == 7 && category !== null" v-model="termsAgreed"></terms-view>
	</transition>
	<thanks-view  :class="{ redborder: redborder }" v-if="orderstep == 15" ></thanks-view>
	</div>   


<div class="summarywrapper mobile" :style="" >	

		<!-- The summary panel on the right hand size -->
	<div v-if="orderstep == 8" class="summary" ref="summary" >
			<div class="header">{{ summary.header }}</div>
		<div class="seperator"></div>

		<div  class="item" v-for="item in summary.items">
			<div class="label">{{ item.label }}</div>
			<div class="name">{{ item.text }}</div>
        
	</div>

		

		<div class="item" v-if="category !== 3">
			<div class="label">Single order price</div>
			<div class="name">{{ summary.price | formatPrice }}</div>
			
		</div>
		<div class="item" v-if="category !== 3">
			<div class="label">VAT</div>
			<div class="name">Included</div>
			
		</div>
		<div class="item" v-if="category !== 3">
			<div class="label">Single order total</div>
			<div class="total">{{ summary.price | formatPrice }}</div>
			
		</div>
	<div class="seperator"></div>
			<div class="button disabled"  v-if="processing">Processing...</div>
			
		<div class="button" v-else @click="addtobasket" :class="{ disabled: !summary.complete || this.addtobasketdisabled }">{{ summary.action }}</div>
	</div>

		<!-- The basket panel on the right hand size -->
		
		<div v-if="orderstep == 9 && category !== 3" class="summary" :style="{ marginTop: '10' + 'px' }" >
			<div class="header">Cart</div>
		<div class="item" v-for="(item, index) in basket">
			<div class="label">{{ item.order.name }}</div>
			<div class="name">{{ item.order.price | formatPrice }}	<svg @click="deleteItem(index)" style="width:24px;height:24px;    transform: translate(15%,27%);color: #24bad4;" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
</svg></div>
		
			
		</div>

	<div class="seperator" v-if="category !== 3"></div>
<div class="item" v-if="kidsclothes">
			<div class="label">Children's Clothes</div>
			<div class="name">£2</div>
		
		</div>
	<div class="item" v-if="category == 5">
			<div class="label">Express Charge</div>
			<div class="name">£5</div></div>

		<div class="item" v-if="category == 0 && discount.enabled || category == 1 || category === 5 && discount.enabled">
			<div class="label" v-if="ownBag">{{ discount.ammount }}0p <span >"{{ discount.name }}"</span> discount</div>
			<div class="totaldiscount">{{ discount.ammount | formatPrice }}</div>
		</div>
		<div class="item" v-if="category == 0 && voucherenabled || category === 5 && voucherenabled">
			<div class="label">{{ voucher.value }}% <span v-if="voucher.name">"{{ voucher.name }}"</span> discount</div>
			<div class="totaldiscount" v-if="ownBag">{{ voucher.value }}%</div>
		</div>
		<div class="item" v-if="category == 4">
			<div class="label"> Total bundle discount</div>
			<div class="totaldiscount">{{ custom.voucher.discount }}%</div>
		</div>

<div class="item" v-if="category !== 3">
<div class="label">{{ "Payment" }}</div>
<div class="name">{{ card.id ? card.brand + " ending in " + card.last4 : null }}</div>		
</div>
<div class="item" v-if="category !== 3">
<div class="label">{{ "Terms and conditions" }}</div>
<div class="name">{{ termsAgreed ? "Agreed" : null }}</div>		
</div>
<div class="item" v-if="category == 0 || category == 5">
	<div v-if="blanketdiscount.enabled" class="label">{{ blanketdiscount.name }}</div><div v-if="blanketdiscount.enabled" class="total">{{ blanketdiscount.pricename }}</div></div>
		<div class="item" v-if="category !== 3 && category !== 4">
			<div class="label">Subtotal</div>
			<div class="name">{{ paydetails.price | formatPrice }}</div>
				
			
			</div>
			<div class="item" v-if="category !== 3 && category !== 4">
			<div class="label">VAT</div>
			<div class="name">Included</div>
			</div>
			<div class="item" v-if="category !== 3 && category !== 4">
			<div class="label">Order Total</div>
			<div class="total">{{ paydetails.price | formatPrice }}</div>
			
			</div>
				<div class="item" v-if="category == 4">
			<div class="label">Subtotal</div>
			<div class="name">{{ paydetails.discountedprice | formatPrice }}</div>
				
			</div> 
					<div class="item" v-if="category == 4">
			<div class="label">VAT</div>
			<div class="name">Included</div>
				
			</div> 
						<div class="item" v-if="category == 4">
			<div class="label">Order Total</div>
			<div class="total">{{ paydetails.discountedprice | formatPrice }}</div>
				
			</div> 
				<div class="item" v-if="category !== 3">
      <input type="text" class="form-control" placeholder="Enter Gift Code" v-model="giftcodes.giftcode">
			<button class="button"v-if="!isButtonDisabled" @click="applyGiftCode(giftcodes.giftcode)">Apply</button>
			<button class="button disabled" v-if="isButtonDisabled" >Apply</button>


    </div>
			<div class="seperator"></div>
			<div class="button disabled" v-if="processing">Processing...</div>
			<div class="button disabled" v-else-if=" !this.termsAgreed || !this.card.id || this.basket.length == 0 ">{{ summary.action2 }}</div>
		<div class="button" v-else @click="trypay" :class="{ disabled: this.basket.length == 0 }">{{ paydetails.price === 0 ? 'Redeem': summary.action2 }}</div>
	

	</div>	
</div>
</div>
<div  class="buttons orderflex">
<div @click="newOrder" v-if="orderstep == 8 && category !== 3" class="orderbuttons">ADD ANOTHER</div>	
<div  @click="stepback" v-show="orderstep !== 15" v-if="(orderstep > 0 && category !== 3) || (orderstep > 1 && category == 3)" class="orderbuttons">BACK</div>
<div v-if="(orderstep < 9 && category !== 3) || (orderstep < 8 && category == 3)" @click="stepforward" :class="{ disabled: nextdisabled }" class="orderbuttons">{{ nexttext }}</div>
</div>
</section>
</template>

<script>
import CategoryView from "./Category";
import PreferencesView from "./Preferences";
import TypeView from "./Type";
import FeatherView from "./Feather";
import StandaloneView from "./Standalone";
import CustomView from "./Custom";
import ExpressView from "./Express";
import RedeemView from "./Redeem";
import SlotsView from "./Slots";
import AddressView from "./Address";
import HouseView from "./House";
import BundleView from "./Bundle";
import PaymentView from "./Payment";
import TermsView from "./Terms";
import ThanksView from "./Thanks";
import Vuetify from 'vuetify';
import OwnbagView from "./Ownbag";
import KidsView from './KidsClothes';
import VoucherView from "./Vouchers";
import Cookies from 'js-cookie';
import {
	loadStripe
} from '@stripe/stripe-js';
import Vue from "vue";

/*
 * Category identifiers
 * 0 - Standalone
 * 1 - House
 * 2 - Bundle
 * 3 - Redeem
 */

export default {
	components: {
		CategoryView,
		PreferencesView,
		KidsView,
		CustomView,
		ExpressView,
		TypeView,
		FeatherView,
		StandaloneView,
		SlotsView,
		AddressView,
		HouseView,
		BundleView,
		PaymentView,
		RedeemView,
		TermsView,
		ThanksView,
		OwnbagView,
		VoucherView
	},

	data() {
		return {

			stripe: loadStripe(STRIPE_KEY),
			category: 0, // The overall category
			window: {
				width: 0,
				height: 0
			},

			//preferences
			preferences: null,
			totalCost: 0,
			selectedDetergent: {
				id: "",
				name: "",
				description: "",
				price: 0,
				type: ""
			},
			selectedDrying: {
				id: "",
				name: "",
				description: "",
				price: 0,
				type: ""
			},
			selectedSoftener: {
				id: "",
				name: "",
				description: "",
				price: 0,
				type: ""
			},
			selectedWashing: {
				id: "",
				name: "",
				description: "",
				price: 0,
				type: ""
			},
			//preferences end


			addtobasketdisabled: false,
			message: {
				text: "",
				showing: false,

			},
			orderstep: 0,
			nextdisabled: false,
			nexttext: 'NEXT',
			redborder: false,

			basket: [],
			paydetails: {
				price: 0,
				discountedprice: 0,
				card: null,
				basket: [{}],
				pride: 0, //pride stuff
				ordername: null,
				appVersion: 30,
				refereal: false,
				giftcodes: {
				price: null,
				giftcode: null,	
				used:null
				}

			},
			urlcheck: 0,
			user: {
				name: null,
				email: null,
				stripe_id: null
			},
			baskettotal: 0,
			discount: {
				enabled: false,
				ammount: 0,
				name: "Own bag",
				applied: false
			},
			blanketdiscount: {
				name: "5% Discount", //block stuff
				enabled: false,
				ammount: 5,
				pricename: "5%",
				value: "percent",
				applied: true
			},

			refereal: {
				id: null,
				user_id: null,
				referral_code: null,
				referring_code: null,
				referred_users: [],
				tokens: 0,
				has_ordered: null
			},

			standalone: {
				load: null,
				whites: false,
				feathers: false,
				softener: {
					type: 0 // Default to no softener preference
				},

			},

			house: {
				house: {
					type: null
				},
				people: null,
				price: null
			},

			bundle: {
				renew: true, // Default to auto renew
				bundle: {
					type: null
				}
			},
			custom: {
				renew: true,
				load: null,
				timestamp: null,
				whites: false,
				feathers: false,
				voucher: {
					value: 0,
					discount: 0
				},

				softener: {
					type: 0 // Default to no softener preference
				},
			},
			express: {
				load: null,
				whites: false,
				feathers: false,
				softener: {
					type: 0 // Default to no softener preference
				},

			},
			redeem: {
				whites: false,
				item: {
					id: null
				}
			},

			slot: {
				start: null,
				end: null
			},
			voucher: {
				name: null,
				word: null,
				value: null,
				singleuse:false,
			},
			giftcodes: {
				giftcode: null,
				price: null,
			},
			voucherenabled: false,
			address: {
				id: null // Default address selection
			},

			card: {
				id: null
			},
			price: {
				price: null
			},
			termsAgreed: false,
			ownBag: false,
			isUpgraded: false,
			kidsclothes: false,
			processing: false, // Whether the order is currently processing
			topOffset: 0,
			stickyTopMargin: 0,
		}
	},

	created() {

		//preferences
		this.$api.get("/preferences/all", {
		}).then(res => {
			this.preferences = res.data;
		});

		//upgraded address
		this.$api.post("/user/isUpgraded", {
		}).then(res => {
			this.isUpgraded = res.data;
		});

		//get tokens
			this.$api.get("/referral").then(res => {
			this.refereal = res.data;
		});

		
		window.addEventListener("scroll", this.onScroll);
		window.scrollTo(0, 0);
		this.$api.get("/user").then(res => {
			this.user = res.data;


		});

		let {
			category,
			redeem
		} = this.$route.query;
		this.$router.replace("/order"); // Replace URL with no query params

		if (category >= 0 && category <= 2) {
			this.category = category;
		}

		if (redeem) {
			this.redeem.item.id = redeem;
			this.category = 3;
		}


		window.addEventListener('resize', this.handleResize);
		this.handleResize();
	},

	mounted() {


	},

	destroyed() {
		window.removeEventListener("scroll", this.onScroll);
		window.removeEventListener('resize', this.handleResize);
	},

	methods: {

		//preferences
		PreferencesCost() {

			this.totalCost = this.selectedDetergent.price + this.selectedDrying.price + this.selectedSoftener.price + this.selectedWashing.price

		},


		applyVoucher(voucher) {
			this.voucher.name = voucher.name;
			this.voucher.word = voucher.word;
			this.voucher.value = voucher.value;
			this.voucher.singleuse = voucher.singleuse;
			this.voucherenabled = true;


		},

		applyGiftCode(giftcode) {
			if (this.paydetails.giftcodes.giftcode && this.paydetails.giftcodes.price <= 0) {
				window.alert("Already applied successfully")
				return

			}
			this.processing = true;
			this.$api.post("/giftcodecheck", { giftcode: giftcode }).then(res => {
				if (!res.data.error) {
					this.giftcodes = res.data
					this.paydetails.giftcodes = res.data
					window.alert("Gift code applied successfully")
					if (this.paydetails.price > this.giftcodes.price) {
						// Set paydetails.price to the remainder of price
						this.paydetails.price -= this.giftcodes.price;
						// Set paydetails.giftcodes.price to 0
						this.paydetails.giftcodes.used = this.giftcodes.price;
						this.paydetails.giftcodes.price = 0;

					} else if (this.paydetails.price < this.giftcodes.price) {
						// Set paydetails.price to 0
						this.paydetails.giftcodes.price -= this.paydetails.price;
						this.paydetails.giftcodes.used = this.paydetails.giftcodes.price

						this.paydetails.price = 0;
						// Set paydetails.giftcodes.price to the remainder
					} else {
						// If paydetails.price equals this.giftcodes.price
						// Set paydetails.price to 0 and paydetails.giftcodes.price to 0
						this.paydetails.giftcodes.used = this.paydetails.giftcodes.price
						this.paydetails.price = 0;
						this.paydetails.giftcodes.price = 0;
						
					}
					this.processing = false;
				}
				else {
					window.alert("gift code not found")
					this.processing = false;

				}
			})
		},
		viewbasket() {
			if (!this.basket.length) {
				return
			}
			this.orderstep = 9;

		},
		handleResize() {
			this.window.width = window.innerWidth;
			this.window.height = window.innerHeight;
		},
		newOrder() {
			this.orderstep = 1


		},

		showerror(text) {


			this.redborder = true;
			this.message.showing = true;
			this.message.text = text;
		},
		hideerrors() {
			this.message.showing = false;
			this.message.text = ""
			this.nextdisabled = false;
			this.nexttext = "NEXT"
			this.redborder = false;

		},


		//Delete item form cart
		deleteItem: function (position) {

			this.paydetails.price = this.paydetails.price - this.basket[position].order.price;


			if (this.basket.length > 2 && this.basket[position].order.kg >= 8) {
				this.custom.voucher.discount = this.custom.voucher.discount - 4;
			} else {
				this.custom.voucher.discount = this.custom.voucher.discount - 2.5;
			}
			if (this.paydetails.price < 1) {
				this.paydetails.price = 0;
			}
			if (this.custom.voucher.discount < 2.5) {
				this.custom.voucher.discount = 0;
			}


			this.basket.splice(position, 1);
			this.paydetails.discountedprice = this.paydetails.price - (this.paydetails.price * (this.custom.voucher.discount / 100));


		},

		stepback() {

			if (this.orderstep <= 0) {
				return;
			}
			this.hideerrors();
			if (this.category == 3 && this.orderstep == 3) {
				this.orderstep = 1;

				return;
			}
			if (this.category == 3 && this.orderstep == 7) {
				this.orderstep = 4;
				return;
			}

			//Step zero reset the red border
			if (this.category == 0) {
				this.redborder = false;
				this.nextdisabled = false;
				this.nexttext = "NEXT";
			}


			if (this.orderstep > 0) {
				this.hideerrors;
				if (this.category == 2 || this.category == 4 && this.orderstep > 0) {

					if (this.orderstep == 6) {


						this.orderstep = 2;

					}
				}

				if (this.category == 1) {

					if (this.orderstep == 6) {

						this.orderstep = 4;

					}

					else {
						--this.orderstep
					}
				} else {
					if (this.orderstep == 6 && this.isUpgraded == true) {
						this.orderstep = 5
					}
					--this.orderstep
				}
			}
		},

		stepforward() {

			window.scrollTo(0, 0);

			if (this.nextdisabled && this.category == 3 && this.orderstep == 4 && !this.address.id) {

				this.showerror("Please Select Address")
				return
			}
			//step forward functionality 
			window.scrollTo(0, 0);

			if (this.category == 3 && this.orderstep == 1) {
				this.orderstep = 3;


				return;
			}
			if (this.category == 3 && this.orderstep == 4) {
				this.orderstep = 7;
				return;
			}
			if (this.nextdisabled) {




				if (!this.category && this.orderstep == 0) {

					this.showerror("Please Select Load Type")
				}

				if (this.category == 2 && this.orderstep == 1 && !this.bundle.type) {

					this.showerror("Please Select Bundle Type")

				}
				if (this.category == 1 && this.orderstep == 1 && !this.house.people) {

					this.showerror("Please Select House Type")

				}
				if (this.category == 4 && this.orderstep == 1 && !this.custom.load) {

					this.showerror("Please Select Load Type")

				}
				if (this.category == 0 && this.orderstep == 1 && !this.standalone.load) {

					this.showerror("Please Select Load Weight")

				}
				if (this.category == 0 && this.orderstep == 3 && !this.standalone.slot) {

					this.showerror("Please Select Time Slot")

				}
				if (this.category == 3 && this.orderstep == 3 && !this.order.slot) {

					this.showerror("Please Select Time Slot")

				}
				if (this.category == 1 && this.orderstep == 3 && !this.order.slot) {

					this.showerror("Please Select Time Slot")

				}
				if (this.category == 5 && this.orderstep == 1 && !this.express.load) {

					this.showerror("Please Select Load Weight")

				}
				if (this.category == 5 && this.orderstep == 3 && !this.express.slot) {

					this.showerror("Please Select Time Slot")

				}
				if (this.category == 1 && this.orderstep == 3 && !this.slot) {

					this.showerror("Please Select Time Slot")

				}
				if (this.orderstep == 4 && !this.address.id) {

					this.showerror("Please Select Address")

				}

				if (this.orderstep == 6 && !this.card.id) {

					this.showerror("Please Select Card")

				}
				if (this.orderstep == 7 && !this.termsAgreed) {

					this.showerror("Please Accept the T&C's")

				}
				return;

			}




			if (this.category == 2 || this.category == 4) {

				if (this.orderstep == 1) {


					this.orderstep = 5;

				}
			}
			if (this.category == 2 || this.category == 4) {

				if (this.orderstep == 1) {


					this.orderstep = 5;

				}
			}

			if (this.category == 1) {

				if (this.orderstep == 4) {


					this.orderstep = 5;

				}


			}
			if (this.orderstep == 4 && this.isUpgraded == true) {

				this.orderstep = 5;

			}


			if (this.orderstep < 9) {
				this.orderstep++;
			}
			// blocking screens on different steps

			if (this.orderstep === 8) {
					if (this.voucherenabled == false && this.refereal.tokens > 0) {
					this.voucher.name = "Refferal";
					this.voucher.word = "refferal";
					this.paydetails.refereal = true;
					this.voucher.value = 5;
					this.voucherenabled = true;
				}

			}
		},



		addtobasket() {
			if (this.summary.complete && !this.processing) {

				this.addtobasketdisabled = true;
				setTimeout(() => {
					this.addtobasketdisabled = false;
				}, 500);
				this.processing = true;

				let {
					category,
					redeem,
					standalone,
					custom,
					express,
					house,
					kidsclothes,
					bundle,
					slot,
					address,
					card,
					price,
					name,
					user,
					urlcheck,
					ownBag,
					stripe_id,
					selectedWashing,
					selectedDetergent,
					selectedSoftener,
					selectedDrying
				} = this;

				let order = {};
				order.urlcheck = urlcheck;
				order.ownbag = ownBag;
				order.kidsclothes = kidsclothes;
				if (category === 0 || category === 1 || category === 5 || category === 3) {
					let {
						start,
						end
					} = slot;
					order.slot = {
						start,
						end
					};
					order.address = address.id;

				}
				if (category !== 3) {
					order.card = card;
					this.paydetails.card = card;
				}
				if (category == 3) {
					order.card = card;
				}

				switch (category) {
					case 0:

						let {
							load, softener, whites, feathers, name
						} = standalone;
						order.standalones = [{
							type: load.type,
							softener: softener.type,
							whites,
							feathers: standalone.feather
						}];
						order.name = standalone.load.name_string + " (standalone)";
						order.preferences = [{
							detergent: selectedDetergent,
							softener: selectedSoftener,
							washing: selectedWashing,
							drying: selectedDrying
						}]
						order.price = price.price;
						break;

					case 1:
						order.houses = [{
							type: house.house.type,
							people: house.people
						}];
						order.name = house.house.name_string + " (" + house.people + " people house)";

						order.price = price.price;
						break;

					case 2:
						order.bundles = [{
							type: bundle.bundle.type,
							renew: bundle.renew
						}];
						order.name = bundle.bundle.name + " (bundle)";
						order.price = price.price;
						break;

					case 3:
						order.redemptions = [{
							id: redeem.item.id,
							whites: redeem.whites
						}];
						order.name = "Bundle redemption";
						break;
					case 4:
						order.custom = [{
							type: custom.load.type,
							renew: custom.renew,
							softener: custom.softener.type,
							whites: custom.whites,
							feathers: custom.feather
						}];
						order.name = custom.load.name_string + " (custom)";
						order.price = price.price;
						order.preferences = [{
							detergent: selectedDetergent,
							softener: selectedSoftener,
							washing: selectedWashing,
							drying: selectedDrying
						}]
						order.kg = custom.load.kg;
						break;

					case 5:

						order.standalones = [{
							type: express.load.type,
							softener: express.softener.type,
							whites: express.whites,
							feathers: express.feather,
							express: "true"
						}];
						order.name = express.load.name_string + " (express)";
						order.preferences = [{
							detergent: selectedDetergent,
							softener: selectedSoftener,
							washing: selectedWashing,
							drying: selectedDrying
						}]
						order.price = price.price;
						break;

				}

				this.paydetails.ordername = order.name;
				order.email = user.email;
				order.stripe_id = this.user.stripe_id;
				order.username = user.name;




				if (this.ownBag == true && this.discount.ammount == 0 && category < 2) {
					this.discount.enabled = true;
					this.discount.ammount = 0.2;
				}

				if (category == 4) {

					if (this.custom.voucher.discount < 25 && custom.load && custom.load.kg < 8 && this.basket.length > 2) {

						this.custom.voucher.discount = this.custom.voucher.discount + 2.5

					}

					if (this.custom.voucher.discount < 25 && custom.load && custom.load.kg >= 8) {
						this.custom.voucher.discount = this.custom.voucher.discount + 4
					}
					if (this.custom.voucher.discount > 25) {
						this.custom.voucher.discount = 25;
					}
				}
				this.paydetails.discountedprice = this.paydetails.price;

				this.basket.push({
					order
				});
				this.custom.feather = false;


				// enebale discount for upgraded address
				if (this.isUpgraded == true) {
					this.blanketdiscount.enabled = true;
				}

				//kids charge
				if (this.kidsclothes == true) {
					order.price = order.price + 2.40
				}
				if (this.blanketdiscount.enabled == true && this.category == 0) {
					order.price = order.price - (order.price * (this.blanketdiscount.ammount / 100));
				}
				if (this.blanketdiscount.enabled == true && this.category == 5) {
					order.price = order.price - (order.price * (this.blanketdiscount.ammount / 100));
				}
				//express charge
				if (this.category == 5) {
					order.price = order.price + 6
				}

				if (this.voucherenabled == true) {

					order.price = order.price - (order.price * (this.voucher.value / 100));
				}
			



				if (this.ownBag == true && this.paydetails.price == 0 && category < 2) {
					this.paydetails.price = this.paydetails.price + order.price - this.discount.ammount;

				} else {
					this.paydetails.price = this.paydetails.price + order.price;


				}

				if (category == 4) {

					this.paydetails.discountedprice = this.paydetails.price - (this.paydetails.price * (this.custom.voucher.discount / 100));
				}
				this.processing = false;


				if (category === 3) {

					this.$api.post("/order", order, {
						redirect: false
					}).then(res => {
						if (res.status !== 200) window.alert(res.data);
						else this.$router.push("/");

					});

				}

			}
		},


		trypay() {
			this.paydetails.card = this.card;
			this.paydetails.basket = this.basket
			for (var i = 0; i < this.basket.length; i++) {
				this.basket[i].order.card = this.paydetails.card

			}
			let vm = this;
			if (this.category == 4) {
				this.paydetails.price = this.paydetails.discountedprice;
			}

			let paydetails = this.paydetails;
			paydetails.voucher = this.voucher
			let basket = this.basket;
			if (Cookies.get('washcycle')) {

				alert("Last order is still processing, please try again in 5 minutes")
				return;
			}
			var inTwentySec = new Date(new Date().getTime() + 0.50 * 6 * 1);
			Cookies.set('washcycle', 'processing', {
				expires: inTwentySec,
			});
			if (this.summary.complete && !this.processing) {
				this.processing = true;
				this.$api.post("/payment", paydetails, basket, {
					redirect: false
				}).then(res => {
					handleServerResponse(res)

				});

				function handleServerResponse(response) {
					const stripe = Stripe(STRIPE_KEY)
					if (response.error) {

						window.alert(response.error);
						// Show error from server on payment form
					} else if (response.data.error) {

						window.alert(response.data.error);
						vm.processing = false;
						// Show error from server on payment form
					} else if (response.data.requires_action) {
						// Use Stripe.js to handle required card action
						stripe.handleCardAction(
							response.data.payment_intent_client_secret
						).then(function (result) {
							if (result.error) {
								window.alert(result.error.message);
								vm.processing = false;
								// Show error in payment form
							} else {
								// The card action has been handled
								// The PaymentIntent can be confirmed again on the server

								vm.$api.post("/payment", {
									payment_intent_id: result.paymentIntent.id, basket
								}, {
									redirect: false
								}).then(res => {
									return res.data;
								}).then(function (confirmresult) {
									if (confirmresult.success) {
										vm.processing = false;
										if (vm.voucher.singleuse === true) {
											let voucherword = { word: vm.voucher.word }
											vm.$api.post("/voucheruse", voucherword);
										}
										paymentsuccess()
									} else {
										vm.processing = false;
										window.alert(confirmresult.error);
									}
								})
							}
						});
					} else {
						vm.processing = false;
						if (vm.voucher.singleuse === true) {
							let voucherword = { word: vm.voucher.word }
							vm.$api.post("/voucheruse", voucherword);
						}
						paymentsuccess();
						// Show success message
					}
				}


				function paymentsuccess() {
					const now = new Date();
					const transaction_id = `${now.getDate()}${now.getMonth() + 1}${now.getFullYear().toString().slice(-2)}${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
					//analitics hooks
					// Enable ecommerce tracking
					// Log a purchase event
					if (document.location.hostname === "washcycle.co.uk" && document.location.pathname === "/order") {
						vm.paydetails.basket.forEach(function (item) {
							// Log item details
							gtag('event', 'item_purchase', {
								transaction_id: transaction_id, // Transaction ID. Required.
								item_name: item.order.name, // Product name. Required.
								item_price: item.order.price, // Unit price.
								quantity: 1 // Quantity.
							});
						});
					}

					//handle survey 
					
					if (!Cookies.get('survey')) {
						let confirm = null;
						confirm = window.confirm("Your order was processed successfully. Would you like to take part in short survey to help us make our services better?");
						if (confirm) {
							Cookies.set('survey', 'true', {
								expires: 30
							})
							window.location.replace("https://forms.gle/uVuL7SHBb4dzMrs17");
						} else {
							Cookies.set('survey', 'true', {
								expires: 30
							});
							vm.orderstep = 15;

						}
					}
					//no survey 
					if (Cookies.get('survey')) {
						vm.orderstep = 15;
						window.scrollTo(0, 0);
					}

				}
			}
		}
	},
	watch: {
		category: function (val, oldVal) {

			this.basket = [];
			this.order = {};
			this.ownbag = false;
			this.kidsclothes = false;
			this.voucher = {
				name: null,
				word: null,
				value: null,
				singleuse:false
			},
				this.voucherenabled = false;
			this.paydetails.price = 0;

			if (this.category == 0 || this.category && this.orderstep == 0) {
				this.hideerrors();

				/* 	if (this.selectedborder){
					this.colorborder(this.selectedborder, this.selectedborderi, false);
					} */

			}
			if (this.category == 3) {

				if (this.orderstep == 0) {
					this.orderstep = 1;



				}

			}

		},
		standalone: {
			// Will fire as soon as the component is created
			deep: true,
			handler(test) {


				// Fetch data about the movie
				if (this.orderstep == 1 && this.standalone.load && this.category == 0) {
					this.hideerrors();
				}

			}
		},
		bundle: {
			// Will fire as soon as the component is created
			deep: true,
			handler(test) {


				// Fetch data about the movie
				if (this.orderstep == 1 && this.bundle && this.category == 2) {

					this.hideerrors();
				}

			}
		},
		house: {
			// Will fire as soon as the component is created
			deep: true,
			handler(test) {


				// Fetch data about the movie
				if (this.orderstep == 1 && this.house.people && this.category == 1) {

					this.hideerrors();
				}

			}
		},
		custom: {
			// Will fire as soon as the component is created
			deep: true,
			handler(test) {


				// Fetch data about the movie
				if (this.orderstep == 1 && this.custom.load && this.category == 4) {

					this.hideerrors();
				}

			}
		},
		express: {
			// Will fire as soon as the component is created
			deep: true,
			handler(test) {


				// Fetch data about the movie
				if (this.orderstep == 1 && this.express.load && this.category == 5) {
					this.hideerrors();
				}
			}
		},

		slot: function (val) {

			if (this.orderstep == 3 && this.slot) {

				this.hideerrors();
			}

		},
		address: function (val) {

			if (this.orderstep == 4 && this.address.id) {

				this.hideerrors();
			}

		},
		card: function (val) {

			if (this.orderstep == 6 && this.card.id) {

				this.hideerrors();
			}

		},
		termsAgreed: function (val) {

			if (this.orderstep == 7 && this.termsAgreed) {

				this.hideerrors();
			}
			if (this.orderstep == 7 && !this.termsAgreed) {

				this.showerror("PLEASE ACCEPT THE T&C");
				this.nextdisabled = true;
				this.nexttext = 'PLEASE ACCEPT THE T&C'
			}

		},
		orderstep: function (val, oldVal) {

			if (this.orderstep == 1 && !this.standalone.load && this.category == 0) {
				this.nextdisabled = true;
				this.nexttext = 'SELECT LOAD'
			}
			if (this.orderstep == 3 && !this.standalone.slot && this.category == 0) {
				this.nextdisabled = true;
				this.nexttext = 'SELECT THE TIME SLOT'
			}
			if (this.orderstep == 3 && !this.express.slot && this.category == 5) {
				this.nextdisabled = true;
				this.nexttext = 'SELECT THE TIME SLOT'
			}

			if (this.orderstep == 1 && !this.express.load && this.category == 5) {
				this.nextdisabled = true;
				this.nexttext = 'SELECT LOAD'
			}

			if (this.orderstep == 3 && !this.order.slot && this.category == 3) {
				this.nextdisabled = true;
				this.nexttext = 'SELECT THE TIME SLOT'
			}
			if (this.orderstep == 3 && !this.order.slot && this.category == 1) {
				this.nextdisabled = true;
				this.nexttext = 'SELECT THE TIME SLOT'
			}
			if (this.orderstep == 1 && !this.bundle.type && this.category == 2) {
				this.nextdisabled = true;
				this.nexttext = 'SELECT BUNDLE'
			}

			if (this.orderstep == 1 && !this.house.type && this.category == 1) {
				this.nextdisabled = true;
				this.nexttext = 'SELECT HOUSE'
			}

			if (this.orderstep == 1 && !this.custom.type && this.category == 4) {
				this.nextdisabled = true;
				this.nexttext = 'SELECT LOAD TYPE'
			}

			if (this.orderstep == 4 && !this.address.id) {
				this.nextdisabled = true;
				this.nexttext = 'SELECT ADDRESS'

			}
			if (this.orderstep == 6 && !this.card.id) {
				this.nextdisabled = true;
				this.nexttext = 'SELECT CARD'
			}
			if (this.orderstep == 7 && !this.termsAgreed) {
				this.nextdisabled = true;
				this.nexttext = 'PLEASE ACCEPT THE T&C'

			}

		},
	},


	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},

	onResize() {
		this.windowWidth = window.innerWidth
	},


	computed: {
		isButtonDisabled: function () {
			if (this.paydetails.price < 1 || !this.termsAgreed || !this.card.id) {
				if (this.paydetails.giftcodes.giftcode === null ) {
					return true; // Button disbled if price < 1 and gift code is not assigned
				} if (this.paydetails.giftcodes.giftcode || this.giftcodes.giftcode.price || this.paydetails.giftcodes.giftcode.price) {
					return false; // Button disabled if gift code is assigned
				}
			} else {
				return false; // Button disabled for other conditions
			}
		},

		slotLimit() {
			let kg, limit;

			switch (this.category) {
				case 0:
					kg = this.standalone.load ? this.standalone.load.kg : 0;
					limit = Math.ceil(kg / 4);
					return (limit > 1) ? limit : null;
					break;

				case 1:

					return this.house.people || null;
					break;

				case 3:
					kg = this.redeem.item.kg;
					limit = Math.ceil(kg / 4);
					return (limit > 1) ? limit : null;
					break;

				case 4:

					kg = this.custom.load ? this.custom.load.kg : 0;
					limit = Math.ceil(kg / 4);
					return (limit > 1) ? limit : null;
					break;
				case 5:

					kg = this.express.load ? this.express.load.kg : 0;
					limit = Math.ceil(kg / 4);
					return (limit > 1) ? limit : null;
					break;

			}
		},

		// Generate order summary
		summary() {

			this.$api.get("/user/preferences").then(res => {
				var MatchedID

				this.selectedDetergent.id = res.data[0].detergent_id
				this.selectedDrying.id = res.data[0].drying_id
				this.selectedSoftener.id = res.data[0].softener_id
				this.selectedWashing.id = res.data[0].washing_id


				MatchedID = this.preferences.detergent.filter(item => item.id.indexOf(res.data[0].detergent_id) !== -1);
				this.selectedDetergent.price = MatchedID[0].price
				this.selectedDetergent.name = MatchedID[0].name
				MatchedID = this.preferences.drying.filter(item => item.id.indexOf(res.data[0].drying_id) !== -1);
				this.selectedDrying.price = MatchedID[0].price
				this.selectedDrying.name = MatchedID[0].name
				MatchedID = this.preferences.softener.filter(item => item.id.indexOf(res.data[0].softener_id) !== -1);
				this.selectedSoftener.price = MatchedID[0].price
				this.selectedSoftener.name = MatchedID[0].name
				MatchedID = this.preferences.washing.filter(item => item.id.indexOf(res.data[0].washing_id) !== -1);
				this.selectedWashing.price = MatchedID[0].price
				this.selectedWashing.name = MatchedID[0].name





				this.PreferencesCost()

			});






			let {
				category,
				redeem,
				standalone,
				custom,
				house,
				bundle,
				slot,
				address,
				express,
				selectedDetergent,
				selectedSoftener,
				selectedDrying,
				selectedWashing,
			} = this;

			let items = []; // All the eventual items
			let price = 0; // The final price
			let header = "Single Order"
			let action = "Add to basket"; // Action text

			let action2 = "Pay Now";
			// Quickly add an item to the items array
			let add = (label, text) => items.push({
				label,
				text
			});

			// Display the category
			add("Category", ["Standalone", "House", "Bundle", "Redeem", "Custom", "Express"][category] || null);

			switch (category) {
				case 0:
					add("Load", standalone.load ? standalone.load.name_string : null);
					add("Content", standalone.whites ? "Whites" : "Colours");

					if (standalone.type === 'duvets') {
						add("Duvet", standalone.feather ? "Feather" : "None / Standard");
					}
					add("Detergent", selectedDetergent.name);
					if (standalone.load.mixed) {
						
					}
					standalone.load.mixed ? add("Drying", "Not Tumble Dried "):add("Drying", selectedDrying.name)
					add("Softener", selectedSoftener.name);
					add("Washing", selectedWashing.name);
					price += selectedDetergent.price;
					price += selectedDrying.price;
					price += selectedSoftener.price;
					price += selectedWashing.price;
					price += standalone.load ? standalone.load.price : 0;
					price += standalone.softener.price ? standalone.softener.price : 0;
					price += standalone.feather ? 2.00 : 0;

					this.price.price = price;
					break;
				case 5:
					add("Load", express.load ? express.load.name_string : null);
					add("Content", express.whites ? "Whites" : "Colours");

					if (express.type === 'duvets') {
						add("Duvet", express.feather ? "Feather" : "None / Standard");
					}
					add("Detergent", selectedDetergent.name);
					express.load.mixed ? add("Drying", "Not Tumble Dried "):add("Drying", selectedDrying.name)
					add("Softener", selectedSoftener.name);
					add("Washing", selectedWashing.name);
					price += selectedDetergent.price;
					price += selectedDrying.price;
					price += selectedSoftener.price;
					price += selectedWashing.price;

					price += express.load ? express.load.price : 0;
					price += express.softener.price ? express.softener.price : 0;
					price += express.feather ? 2.00 : 0;
					this.price.price = price;
					break;
				case 1:
					add("Load", house.house ? house.house.name_string : null);
					add("People", house.people ? house.people : null);
					header = "House Order"
					price += house.price ? house.price : 0;
					this.price.price = price;
					break;

				case 2:
					action = "Add bundle";
					header = "Single Bundle"
					add("Bundle", bundle.bundle.type ? bundle.bundle.name : null);
					add("Monthly auto-renwal", bundle.renew ? "Yes" : "No");

					price += bundle.bundle.price ? bundle.bundle.price : 0;
					this.price.price = price;
					break;

				case 3:
					action = "Redeem bundle";
					header = "Reedem Bundle"
					add("Redeem", redeem.item ? redeem.item.name_string : null);
					add("Content", redeem.whites ? "Whites" : "Colours");

					break;

				case 4:

					action = "Add to bundle";
					action2 = "Pay & Create Bundle";
					add("Load", custom.load ? custom.load.name_string : null);
					add("Content", custom.whites ? "Whites" : "Colours");
					add("Monthly auto-renwal", custom.renew ? "Yes" : "No");
					header = "Custom Bundle"
					if (custom.type === 'duvets') {
						add("Duvet", custom.feather ? "Feather" : "None / Standard");
					}
					if (this.basket.length > 2 && custom.load && custom.load.kg < 8) {
						add("Custom bundle discount", "2.5%");

					}
					if (custom.load && custom.load.kg >= 8) {
						add("Custom bundle discount", "4%");

					}
					add("Detergent", selectedDetergent.name);
					custom.load.mixed ? add("Drying", "Not Tumble Dried "):add("Drying", selectedDrying.name)
					add("Softener", selectedSoftener.name);
					add("Washing", selectedWashing.name);
					price += selectedDetergent.price;
					price += selectedDrying.price;
					price += selectedSoftener.price;
					price += selectedWashing.price;

					price += custom.load ? custom.load.price : 0;
					price += custom.softener.price ? custom.softener.price : 0;
					price += custom.feather ? 2.00 : 0;
					this.price.price = price;
					break;

			}

			// Both Standalone and House orders have a slot and address
			if (category === 0 || category === 1 || category === 3 || category === 5) {
				add("Collection", slot.start && slot.end ? slot.time_string + " " + slot.day_string : null);
				add("Address", address.id ? address.address_line_1 + ", " + address.postcode : null);
			}

			// As long as category is not redemption, add payment


			// If the category is not null, add terms view


			if (category !== null && category === 5 || category < 2) {

				if (this.ownBag == true && !this.discount.enabled) {
					this.discount.enabled = true;
					this.discount.ammount = 0.2;
					if (this.paydetails.price !== 0 && this.discount.applied !== true) {
						this.paydetails.price = this.paydetails.price - this.discount.ammount;
						this.discount.applied = true;
					}
				}


				if (this.ownBag == false || this.basket.length == 0) {
					if (this.paydetails.price !== 0 && this.discount.applied == true) {
						this.paydetails.price = this.paydetails.price + this.discount.ammount;
						this.discount.applied = false;

					}

					this.discount.enabled = false;
					this.discount.ammount = 0;
				}
			}


			// Set the discount value


			// Loop over and disable completion, make null text safe
			let complete = true;
			for (let i = 0; i < items.length; i++) {
				if (!items[i].text) {
					items[i].text = "--";
					complete = false; // If one item is incomplete, disable
				}
			}

			let summary = {
				items,
				price,
				complete,
				header,
				action,
				action2,

			}

			return summary;
		}
	},

	filters: {
		formatPrice: price => price !== 0 ? "£" + price.toFixed(2) : "--",
		VatPrice: price => price !== 0 ? "£" + (price * 0.20).toFixed(2) : "--",
		VatIncluded: price => price !== 0 ? "£" + (price * 1.20).toFixed(2) : "--"
	}
}

</script>

<style lang="less">
@mobile: ~'(min-width: 10px)';
@hidesteps: ~'(max-width: 620px)';

section.order {
	.badge {
		background: #24bad4;
		color: white;
		text-decoration: none !important;
		width: 18px;
		height: 18px;
		position: absolute;
		border-radius: 20px;
		text-align: center;
		right: -12px;
		top: -7px;
		line-height: 17px;
		font-size: 13px;
	}

	.stepshide {
		@media @hidesteps {
			display: none;

		}
	}

	.theme--light.v-stepper .v-stepper__step__step {
		color: white;
		color: #fafafa;
		background: #49bad4;
	}

	.redborder {
		border: 1px solid rgba(241, 79, 79, 0.87);
		padding: 10px;
	}

	.message {
		padding: 20px;
		margin: 20px;
		border-radius: 10px;
		cursor: pointer;
		color: rgba(241, 79, 79, 0.87) !important;
		background-color: #fbdede;
		color: #cc3333;
	}

	.warning {
		background-color: #fbdede;
		color: #cc3333;
	}

	.warning .theme--light.v-btn {
		color: rgba(241, 79, 79, 0.87) !important;
	}

	.left {
		margin-top: -10px;
		text-align: left;
	}

	.slide-fade-enter-active {
		transition: all .3s ease;
	}

	.slide-fade-leave-active {
		transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.slide-fade-enter,
	.slide-fade-leave-to

	/* .slide-fade-leave-active below version 2.1.8 */
		{
		transform: translateX(-20px);
		opacity: 0;
	}





	padding: 10px;
	margin: auto;

	.mobile {
		display: none;


		@media @mobile {
			display: initial;

		}
	}

	.orderbuttons {
		border: solid 1px #01BAD4;
		border-radius: 4px;
		padding: 14px;
		margin: 5px;
		color: #01BAD4;

		text-align: center;
		cursor: pointer;
		font-size: 18px;
		max-width: 1070px;
		margin: auto;
		margin-left: 10px;
		flex-grow: 1;
		display: inline-flex;
		flex-direction: column;
		align-items: center;

		&.disabled {
			opacity: 0.5;
			color: #888;
			cursor: default;
		}
	}

	.orderflex {
		display: flex;
		flex-wrap: wrap;
		max-width: 1050px;
		margin: auto;
		padding-top: 50px;
	}

	.desktop {
		display: initial;

		@media @mobile {
			display: none;
		}
	}

	.summarywrapper .mobile {
		@media @mobile {
			display: block;
			width: 95vw;
			margin-top: 30px;
		}

	}

	.wrapper-full {
		display: flex;

		@media @mobile {
			display: block;
		}

	}

	.primary {
		flex-grow: 2;
		min-width: 0;
		position: relative;

		// Overlay all the sections to prevent interaction while processing
		.lock {
			left: 0;
			top: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.5);
		}

		// The headers at the top of each section of choice
		.header {
			font-size: 20px;
			padding-bottom: 10px;
			border-bottom: solid 1px #CCC;
			margin-top: 30px;
			margin-bottom: 10px;

			// Add link button option to header
			display: flex;
			justify-content: space-between;
			align-items: center;

			.link {
				font-size: 14px;
				color: #01BAD4;
				text-decoration: underline;
				cursor: pointer;
				padding: 4px 0;
			}

			&.active {
				border-bottom: solid 1px #01BAD4;
			}
		}

		.options {
			display: flex;
			flex-wrap: wrap;
		}

		.option {
			border: solid 1px #DDD;
			border-radius: 4px;
			padding: 14px;
			margin: 5px;
			text-align: center;
			cursor: pointer;
			font-size: 18px;
			-webkit-transition: border 100ms ease-out;
			-moz-transition: border 100ms ease-out;
			-o-transition: border 100ms ease-out;

			flex-grow: 1;
			display: inline-flex;
			flex-direction: column;
			align-items: center;

			.label {
				font-size: 12px;
				padding: 4px 10px;
				color: #01BAD4;
				border: solid 1px #01BAD4;
				border-radius: 4px;
				margin-bottom: 8px;
				text-transform: uppercase;
			}

			.image {
				margin-bottom: 14px;
				width: 80px;
				height: 80px;
			}

			.desc {
				font-size: 14px;
				color: #888;
				margin: auto;
				padding: 10px;
			}

			.price {
				font-size: 13px;
				margin-top: 4px;
				color: #01BAD4;
			}

			&.selected {
				border: solid 1px #01BAD4 !important;
				color: #01BAD4;
				cursor: default;

				.label {
					color: #FFF;
					background-color: #01BAD4;
				}
			}

			&.disabled {
				opacity: 0.5;
				color: #888;
				cursor: default;
			}

			// Dashed (template) style
			&.dashed {
				border: dashed 1px #CCC;
				color: #888;
				font-size: 14px;
				justify-content: center;
			}
		}

		.warning,
		.alert,
		.notification {
			text-align: center;
			padding: 10px;
			border-radius: 4px;
			font-size: 14px;
			margin: 10px 0;
		}

		.notification {
			background-color: #DEE6FB;
			color: #3388CC;
		}

		.warning {
			background-color: #fbf5de;
			color: #cc9833;
		}

		.alert {
			background-color: #fbdede;
			color: #cc3333;
		}
	}

	.summary {
		width: 300px;
		align-self: flex-start;
		border: solid 1px #DDD;
		border-radius: 4px;
		padding: 10px;
		box-sizing: border-box;
		margin-left: 30px;
		flex-shrink: 0;
		transition: margin-top 0.1s ease;

		@media @mobile {
			width: 100%;
			max-width: 1061px;

			margin: 20px 0px 0px 0px;
		}

		.button {
			font-size: 13px;
			background-color: #01BAD4;
			color: #FFF;
			padding: 10px;
			text-align: center;
			border-radius: 4px;
			cursor: pointer;

			&.disabled {
				background-color: #DDD;
				cursor: default;
			}
		}

		.header {
			font-weight: 500;
			font-size: 16px;
			color: #888;
			margin-right: 10px;
			text-align: center;

		}

		.seperator {
			background-color: #DDD;
			margin-top: 10px;
			width: auto;
			height: 1px;
			margin-bottom: 10px;
		}

		.item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 10px;
		}

		.label {
			font-weight: 500;
			font-size: 14px;
			color: #888;
			margin-right: 10px;
		}

		.name {
			font-size: 15px;
		}

		.total {
			font-size: 18px;
			font-weight: 500;
			color: #01BAD4;
		}

		.totaldiscount {
			font-size: 18px;
			font-weight: 500;
			color: #27ae60;
		}
	}
}
</style>
