<template>
  <section class="vouchers">
    <div class="header">Apply a voucher</div>
    
      <span>Please enter a voucher you would like to apply below</span>
      <div class="input-box">  <input type="text" v-model="vouchername" class="voucher-input" name="voucher">
      <div class="link"  v-if="!processing"  @click="checkvoucher">Apply</div>
      <div class="link disabled" v-if="processing">Processing...</div>
      </div>
    

  </section>
</template>

<script>
export default {
  props: ["value"],
data() {
		return {
    vouchername:null,
    processing:false,
		voucherdata:null,
	
		}
	},
  methods: {
   checkvoucher() {
     this.processing = true;
     let voucherword = {word:this.vouchername}
         this.$api.post("/vouchercheck", voucherword ).then(res => {
           console.log(res.data.error)
				    if(!res.data.error){
                let voucherdata = res.data
               
               window.alert("Voucher applied successfully")
                this.$emit("applyVoucher", voucherdata);
               this. vouchername = null,
               this.processing = false;





           }
           
           if (res.data.error) {
             window.alert(res.data.error)
            let voucherdata = null;
               this.vouchername = null,
                  this.processing = false;
                }
				});
       }
 
}}
</script>

<style lang="less" scoped>
section.vouchers {
  font-size: 14px;

  li:not(:last-of-type) {
    margin-bottom: 12px;
  }
  input{color: #01BAD4;
    border: 1px solid #01BAD4;
    padding: 10px;
    border-radius: 10px;
    width: 80%;
        height: 40px;
    display: inline;}
	.link {
	  font-size: 14px;
    color: #01BAD4;
    cursor: pointer;
    padding: 4px 0;
    border: 1px solid;
    padding-top: 10px;
    border-radius: 20px;
    width: 60px;
    text-align: center;
    display: inline;
    margin-left: 19px;
			&:hover { background:#01BAD4; color: white; }
      &:active {  color: #01BAD4; }
      
    }
    
    .input-box{padding-top:20px;display: flex;}
    .disabled {
				opacity: 0.5;
				color: #888;
				cursor: default;
    }
  .ownbag {
    cursor: default;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.025);
    border-radius: 4px;

    transition: background-color 0.3s, color 0.3s;

    &.active {
      background-color: rgba(46, 204, 113, 0.1);
      color: rgba(39, 174, 96, 1);
    }

    span {
      margin-left: 10px;
      display: inline-block;
    width: 90%;
    }
    input{    position: relative;
    top: -6px;}
    
  }
}
</style>
