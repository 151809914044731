<template lang="html">
<section class="bundle">
	<div class="header">Choose a subscription bundle</div>
	<loader v-if="loading" name="subscription bundles"></loader>
	<div class="options" v-else>
		<div class="option" :class="{ selected: selected.bundle.type === bundle.type }" v-for="bundle in bundles" @click="setBundle(bundle)">
			<div class="name">{{ bundle.name }}</div>
			<div class="desc" v-if="bundle.softener_upgrade">Free softener upgrade</div>

			<div class="items">
				<div class="item" v-for="item in bundle.items">
					<div class="name">{{ item.name_string }} x{{ item.credits }}</div>
					<div class="desc">{{ item.treatment_string }}</div>
				</div>
			</div>

			<div class="price">{{ bundle.price | formatPrice }} / month</div>
		</div>
	</div>

	<div class="header">Choose a renewal option</div>
	<div class="options">
		<div class="option" :class="{ selected: selected.renew }" @click="setRenew(true)">Automatically renew every month</div>
		<div class="option" :class="{ selected: !selected.renew }" @click="setRenew(false)">Do not auto-renew</div>
	</div>
</section>
</template>

<script>
export default {
	props: [
		"value"
	],

	data() {
		return {
			loading: true, // Whether loading data from AJAX
			bundles: [], // The bundles
			selected: this.value
		}
	},

	created() {
		this.$api.get("/products/bundles").then(res => {
			this.loading = false;
			this.bundles = res.data;
		});
	},

	methods: {
		setBundle(bundle) {
			this.selected.bundle = bundle;
			this.$emit("input", this.selected);
		},

		setRenew(renew) {
			this.selected.renew = renew;
			this.$emit("input", this.selected);
		}
	},

	filters: {
		formatPrice: price => "£" + price.toFixed(2)
	}
}
</script>

<style lang="less" scoped>
section.bundle {
	.items {
		border: solid 1px #DDD;
		border-radius: 4px;
		margin: 10px 0;
	}

	.item {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 10px;

		&:not(:last-of-type) {
			border-bottom: solid 1px #DDD;
		}
	}
}
</style>
