<template lang="html">
<section class="user">


<div class="">

	<h1>User Details</h1>
 
<div style="padding: 10px;"><span style="display:block;padding-right: 50px;">Name: </span><input v-model="newuser.name" :placeholder="user.name" class="details-input" ></div>
<div class="email" style="padding: 10px;"><span  style="display:block;padding-right: 50px;">Email: </span><input v-model="newuser.email" type="email" class="details-input" :placeholder="user.email"></div>
<div style="padding: 10px;"><span style="display:block;padding-right: 20px;">Password: </span><input disabled placeholder="********" class="details-input" ><span @click="showpasschange" class="pill selected button">Change Password</span></div>
<div @click="savechanges()" style="text-align:right;"><span class="pill selected button">Save Changes</span></div>
<div @click="cancel()" style="text-align:right;"><span class="pill button">Cancel</span>

</div>
<pass-modal v-if="showmodal" :user="user" @close="showmodal = false"></pass-modal>	
</div>	
</section>
</template>

<script>
import Vuetify from 'vuetify';
import PassModal from "./PassModal"
export default {
	components: {
		PassModal,
	},

	data() {
		return {
			loading: true,
			processing: false,
			showmodal: false,
		
	user: {
				name: null,
				email: null,
				id:null
			},
	newuser: {
				name: null,
				email: null,
				id:null
			},
			showNewCard: false
		}
	},

	created() {
		this.$api.get("/user").then(res => {
			this.user = res.data;
			this.loading = false;
		});
	},

	methods: {
		reload() {
			
		},

		showpasschange() {
		
			this.showmodal = true;
		
		
		},
			cancel(){
			this.$router.push("/");
		},
		savechanges(){
		this.newuser.id = this.user.id;
		var emailCheck=/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
		if( this.newuser.email) {
		if ( !emailCheck.test(this.newuser.email) ){
		window.alert("Enter a valid email address");
		return;
		}}
	this.$api.post("/user/updatedetails", this.newuser ).then(res => {
				    if(res.status == '200'){
					   	window.alert("User details updated successfully")
						
				   }
				});




		},
	setcategory(i){
	this.category = i;

	},
		close() {
			
		},

	
		
	}
}
</script>

<style lang="less" scoped>
@mobile: ~'(max-width: 1100px)';
section.user {
.details-input{padding: 10px;
margin-top:10px;
color:#01bad4;
font-size: 14px;
border: solid 1px #01bad4;
border-radius: 7px;}

.pill{padding: 8px 18px;
margin-right: 20px;
color:
#01bad4;
text-transform: uppercase;
font-size: 14px;
border: solid 1px #01bad4;
width: 27%;
display: inline-block;
margin: 10px;
border-radius: 100px;
cursor: pointer;
text-align:center;

@media @mobile {
display: block;
margin-left:-20px;
text-align:center;
width: 100%;

}

.email span {
display: inline-block;
@media @mobile {
display: block;


}
}
.email {

@media @mobile {
display: block;


}
}
}
.selected{background: #01bad4;
color:white;}


}
</style>
