<template lang="html">
<section class="house">
	<div class="header">Choose a house discount type</div>

	<loader v-if="loading" name="house discount types"></loader>
	<div class="options types" v-else>
		<div class="option" :class="{ selected: house.type === selected.house.type }" v-for="house in houses" @click="setHouse(house)">
			<div class="label">Type {{ house.type }}</div>
			<div class="name">{{ house.name_string }}</div>
			<div class="desc">{{ house.treatment_string }}</div>
		</div>
	</div>

	<div class="header">Choose the number of people in your house</div>
	<div class="options people" v-if="selected.house.max">
		<div class="option" :class="{ selected: selected.people === price.people }" v-for="price in selected.house.prices" @click="setPeople(price.people)">
			<div class="name">{{ price.people }}</div>
			<div class="price">{{ price.per | formatPrice }}/pp</div>
			<div class="price">{{ price.total | formatPrice }}</div>
		</div>
	</div>
	<div class="warning" v-else>You must select a house discount type</div>

</section>
</template>

<script>
export default {
	props: [
		"value"
	],

	data() {
		return {
			loading: true, // Whether loading from AJAX
			houses: [], // Array of possible house bundle options
			selected: this.value // The current selected house and people number
		}
	},

	created() {
		this.$api.get("/products/house").then(res => {
			this.loading = false;
			this.houses = res.data;
		});
	},

	methods: {
		setHouse(house) {
			this.selected.house = house;
			this.selected.people = null;
			this.selected.price = this.price;
			this.$emit("input", this.selected);
		},

		setPeople(people) {
			this.selected.people = people;
			this.selected.price = this.price;
			this.$emit("input", this.selected);
		}
	},

	computed: {
		price() {
			let price = this.selected.house.prices.find(price => price.people === this.selected.people);
			return price ? price.total : 0;
		}
	},

	filters: {
		formatPrice: price => "£" + price.toFixed(2)
	}
}
</script>

<style lang="less" scoped>
section.house {
	.options.people .option {
		flex-grow: unset;
	}
}
</style>
