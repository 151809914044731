<template lang="html">
<section class="content">
	<div class="header">Choose the duvet type</div>
	<div class="options">
		<div class="option" :class="{ selected: !feather }" @click="setFeather(false)">
			<div class="name">None / Standard duvet</div>
            <div class="price">{{ "£0.00" }}</div>
		</div>

		<div class="option" :class="{ selected: feather }" @click="setFeather(true)">
			<div class="name">Feather duvet</div>
            <div class="price">{{ "£2.00" }}</div>
		</div>
	</div>
</section>
</template>

<script>


export default {
	props: [
		"value"
	],

	data() {
		return {
			feather: this.value
		}
	},

	methods: {
		setFeather(feather) {
			this.feather = feather;
			this.$emit("input", this.feather);
		},
	}
}
</script>
