<template lang="html">
<section class="category">
	<div class="header">What sort of order would you like to make?</div>
	<div class="options">
		<div class="option" :class="{ selected: selected === 0 }" @click="setCategory(0)">
			<img class="image" :src="img.standalone">
			<div class="name">Standalone</div>
			<div class="desc">A single laundry job</div>
		</div>
 <div class="option" :class="{ selected: selected === 5 }" @click="setCategory(5)">
			<img class="image" :src="img.express">
			<div class="name">Express Standalone</div>
			<div class="desc">Guaranteed same day return</div>
		</div>
		<div class="option" :class="{ selected: selected === 2 }" @click="setCategory(2)">
			<img class="image" :src="img.bundle">
			<div class="name">Bundle</div>
			<div class="desc">A discounted subscription</div>
		</div>
			<div class="option" :class="{ selected: selected === 4 }" @click="setCategory(4)">
			<img class="image" :src="img.custom">
			<div class="name">Custom Bundle</div>
			<div class="desc">A custom discounted subscription</div>
		</div>
	</div>
</section>
</template>

<script>
import standalone from "../../assets/icons/standalone.png";
import express from "../../assets/icons/express.png";
import house from "../../assets/icons/house.png";
import bundle from "../../assets/icons/bundle.png";
import custom from "../../assets/icons/custom.png";
export default {
	props: [
		"value"
	],

	data() {
		return {
			img: { standalone, house, bundle, express, custom },
			selected: this.value
		}
	},

	methods: {
		setCategory(id) {
			this.selected = id;
			this.$emit("input", id);
		}
	}
}
</script>
