// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.ownbag[data-v-fc7a7992] {
  font-size: 14px;
}
section.ownbag li[data-v-fc7a7992]:not(:last-of-type) {
  margin-bottom: 12px;
}
section.ownbag .ownbag[data-v-fc7a7992] {
  cursor: default;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.025);
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}
section.ownbag .ownbag.active[data-v-fc7a7992] {
  background-color: rgba(46, 204, 113, 0.1);
  color: #27ae60;
}
section.ownbag .ownbag span[data-v-fc7a7992] {
  margin-left: 10px;
  display: inline-block;
  width: 90%;
}
`, "",{"version":3,"sources":["webpack://./client/views/Order/Ownbag.vue","webpack://./Ownbag.vue"],"names":[],"mappings":"AACA;EACE,eAAA;ACAF;ADDA;EAII,mBAAA;ACAJ;ADJA;EAQI,eAAA;EACA,aAAA;EACA,sCAAA;EACA,kBAAA;EAEA,6CAAA;ACFJ;ADII;EACE,yCAAA;EACA,cAAA;ACFN;ADfA;EAqBM,iBAAA;EACA,qBAAA;EACF,UAAA;ACHJ","sourcesContent":["\nsection.ownbag {\n  font-size: 14px;\n\n  li:not(:last-of-type) {\n    margin-bottom: 12px;\n  }\n\n  .ownbag {\n    cursor: default;\n    padding: 20px;\n    background-color: rgba(0, 0, 0, 0.025);\n    border-radius: 4px;\n\n    transition: background-color 0.3s, color 0.3s;\n\n    &.active {\n      background-color: rgba(46, 204, 113, 0.1);\n      color: rgba(39, 174, 96, 1);\n    }\n\n    span {\n      margin-left: 10px;\n      display: inline-block;\n    width: 90%;\n    }\n   \n  }\n}\n","section.ownbag {\n  font-size: 14px;\n}\nsection.ownbag li:not(:last-of-type) {\n  margin-bottom: 12px;\n}\nsection.ownbag .ownbag {\n  cursor: default;\n  padding: 20px;\n  background-color: rgba(0, 0, 0, 0.025);\n  border-radius: 4px;\n  transition: background-color 0.3s, color 0.3s;\n}\nsection.ownbag .ownbag.active {\n  background-color: rgba(46, 204, 113, 0.1);\n  color: #27ae60;\n}\nsection.ownbag .ownbag span {\n  margin-left: 10px;\n  display: inline-block;\n  width: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
