<template lang="html">
<section class="content">
    <div class="header">
        <span class="calc-text">Choose a laundry load type</span>
        <span class="calc link" @click="showCalculator = true">Laundry calculator</span>
    </div>

    <!-- Box for options that include tumble drying -->
    <div class="options-box">
        <div class="options-header">Includes Tumble Drying</div>
        <div class="options">
            <div class="option" :class="{ selected: type === 'clothes' }" @click="setType('clothes')">
                <div class="name">Clothes</div>
            </div>
            <div class="option" :class="{ selected: type === 'towels' }" @click="setType('towels')">
                <div class="name">Towels, Sheets</div>
            </div>
            <div class="option" :class="{ selected: type === 'duvets'}" @click="setType('duvets')">
                <div class="name">Duvets, Pillows</div>
            </div>
        </div>
    </div>

    <!-- Box for options that do not include tumble drying -->
    <div class="options-box">
        <div class="options-header">Not Tumble Dried - For line drying at home</div>
        <div class="options">
            <div class="option" :class="{ selected: type === 'mixed' }" @click="setType('mixed')">
                <div class="name">Wash, Spin & Fold<span class="badge2 eco">Eco-Friendly!</span></div>
            </div>
        </div>
    </div>

    <calculator v-if="showCalculator" @close="showCalculator = false"></calculator>
</section>
</template>

<script>
import Calculator from "../../components/Calculator";
export default {

components: {
	
		Calculator
	},

	props: [
		
		"value"
	],

	data() {
		return {
			type: this.value,
			showCalculator: false
			
		}
	},

	methods: {
		setType(type) {
			this.type = type;
			this.$emit("input", this.type);
			
			
		},
	
	}
}
</script>
<style lang="less">
.badge2 {
		background-color: #4CAF50;
			border-radius: 25px;
			padding: 5px;
			margin-left: 10px;
			color: #ddd;
}

.eco {
	background-color: #01d457;
	/* Green background */
}
.options-box {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
}
.options-header {
    font-weight: bold;
    margin-bottom: 10px;
}
@mobile: ~'(max-width: 1100px)';
@mobile900: ~'(max-width: 900px)';
section.content {
.calc{
	
	@media @mobile {display: inline-flex;}
}
}
@media @mobile900 {.badge2 {
			display: block;
			margin-top: 3px;}
}
</style>