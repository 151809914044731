<template lang="html">
<section class="payment">
	<div class="header"><span>Choose a payment method</span><span class="link" @click="editCards">Edit payment methods</span></div>

	<loader name="your cards" v-if="loading"></loader>
	<div class="options" v-else>
		<div class="option" :class="{ selected: selected.id === card.id }" v-for="card in cards" @click="setCard(card)">
			<div class="brand">{{ card.brand }}</div>
			<div class="number">•••• •••• •••• {{ card.last4 }}</div>
			<div class="expires">{{ card.expiry_string }}</div>
		</div>

		<div class="option dashed" @click="showNewCard = true">Add a new card</div>
	</div>

	<new-card v-if="showNewCard" @close="close"></new-card>
</section>
</template>

<script>
import NewCard from "../../components/NewCard";

export default {
	components: {
		NewCard
	},

	props: [
		"value"
	],

	data() {
		return {
			loading: true, // Whether loading AJAX data
			cards: [],
			selected: this.value,

			showNewCard: false
		}
	},

	created() {
		this.reload();
	},

	methods: {
		reload() {
			this.$api.get("/user/cards").then(res => {
				this.loading = false;
				this.cards = res.data.cards;
			});
		},

		editCards() {
			this.$router.push("/billing");
		},

		setCard(card) {
			this.selected = card;
			this.$emit("input", card);
		},

		close() {
			this.showNewCard = false;
			this.reload();
		}
	}
}
</script>

<style lang="less" scoped>
section.payment {
}
</style>
