<template lang="html">
<section class="messages">
	<div v-if="!loading && messages.length" class="header">Messages</div>

	<loader name="your messages" v-if="loading"></loader>

	<div class="messages"   v-for="(message, index) in messages">
			<div class="meta">
				<div class="message" @click="dismiss(index)"  :class="message.message_type">{{ message.message_content }}</div>
			
			</div></div>


</section>
</template>

<script>

export default {
	data() {
		return {
			

			loading: true, // Loading data from api
			messages: []
		}
	},

	created() {
		this.reload();
	},

	methods: {
			reload() {
			this.$api.get("/user/messages").then(res => {
				this.loading = false;
                this.messages = res.data;
                
			});
		},
    dismiss(index){
 
   let id = {id: this.messages[index].id}
  this.$api.post("/user/dismiss", id, { redirect: false }).then(res => {
			if (res.status === 200){
			
			this.messages.splice(index, 1);
			 
			}
				});
				

    },
	

		

		
	},

	filters: {
		formatPrice: price => "£" + price.toFixed(2)
	}
}
</script>

<style lang="css" scoped>

.message{padding: 20px;
    margin: 20px;
    border-radius: 10px;
	cursor: pointer;
 
  
}
.button{background: #fef7f7!important;
    
    border-radius: 20px;}
.warning{background-color: #fbdede;
    color: #cc3333;}
.warning .theme--light.v-btn {
    color: rgba(241, 79, 79, 0.87)!important;
}
.left{margin-top:-10px;text-align: left;}
	
</style>
