// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.profile .pill[data-v-63a6f8b1] {
  padding: 8px 18px;
  margin-right: 20px;
  color: #01bad4;
  text-transform: uppercase;
  font-size: 14px;
  border: solid 1px #01bad4;
  width: 27%;
  display: inline-block;
  margin: 10px;
  border-radius: 100px;
  cursor: pointer;
  text-align: center;
}
@media (max-width: 1100px) {
section.profile .pill[data-v-63a6f8b1] {
    display: block;
    text-align: center;
    width: 100%;
}
}
section.profile .icon[data-v-63a6f8b1] {
  fill: #01bad4;
  width: 24px;
  height: 24px;
  top: 5px;
  position: relative;
}
section.profile .iconSelected[data-v-63a6f8b1] {
  fill: white;
  width: 24px;
  height: 24px;
  top: 5px;
  position: relative;
}
section.profile .selected[data-v-63a6f8b1] {
  background: #01bad4;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./client/views/Profile.vue","webpack://./Profile.vue"],"names":[],"mappings":"AAEA;EACM,iBAAA;EACN,kBAAA;EACA,cAAA;EAEA,yBAAA;EACA,eAAA;EACA,yBAAA;EACA,UAAA;EACA,qBAAA;EACA,YAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;ACFA;ADGA;AAAA;IACA,cAAA;IACA,kBAAA;IACA,WAAA;ACAE;AACF;ADlBA;EAsBM,aAAA;EAAc,WAAA;EAAY,YAAA;EAAa,QAAA;EAAS,kBAAA;ACGtD;ADzBA;EAuBc,WAAA;EAAY,WAAA;EAAY,YAAA;EAAa,QAAA;EAAS,kBAAA;ACS5D;ADhCA;EAwBU,mBAAA;EACV,YAAA;ACWA","sourcesContent":["\n@mobile: ~'(max-width: 1100px)';\nsection.profile {\n.pill{padding: 8px 18px;\nmargin-right: 20px;\ncolor:\n#01bad4;\ntext-transform: uppercase;\nfont-size: 14px;\nborder: solid 1px #01bad4;\nwidth: 27%;\ndisplay: inline-block;\nmargin: 10px;\nborder-radius: 100px;\ncursor: pointer;\ntext-align:center;\n@media @mobile {\ndisplay: block;\ntext-align:center;\nwidth: 100%;\n\n}\n\n}\n.icon{fill: #01bad4;width: 24px;height: 24px;top: 5px;position: relative;}\n.iconSelected{fill: white;width: 24px;height: 24px;top: 5px;position: relative;}\n.selected{background: #01bad4;\ncolor:white;}\n}\n","section.profile .pill {\n  padding: 8px 18px;\n  margin-right: 20px;\n  color: #01bad4;\n  text-transform: uppercase;\n  font-size: 14px;\n  border: solid 1px #01bad4;\n  width: 27%;\n  display: inline-block;\n  margin: 10px;\n  border-radius: 100px;\n  cursor: pointer;\n  text-align: center;\n}\n@media (max-width: 1100px) {\n  section.profile .pill {\n    display: block;\n    text-align: center;\n    width: 100%;\n  }\n}\nsection.profile .icon {\n  fill: #01bad4;\n  width: 24px;\n  height: 24px;\n  top: 5px;\n  position: relative;\n}\nsection.profile .iconSelected {\n  fill: white;\n  width: 24px;\n  height: 24px;\n  top: 5px;\n  position: relative;\n}\nsection.profile .selected {\n  background: #01bad4;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
