<template lang="html">
<section class="main">
	<div class="alert" v-if="alert.text || alert.url"><div class="wrapper-full">
		<span>{{ alert.text }}</span>
		<a v-if="alert.url" :href="alert.url">Find out more</a>
	</div></div>

	<div class="navbar desktop"><div class="wrapper-full">
		<div class="logo" @click="home">
			<img :src="img.logoWhite">
			<div class="title">Wash Cycle</div>
			<div class="beta">Beta</div>
			
		</div>
		
		<div class="link" @click="profile">Profile</div>
		<div class="link" @click="home">Orders</div>

		<div class="spacer"></div>
		<div class="button" @click="order" v-if="!onOrderPage">New order</div>

		<loader v-if="loading" :simple="true"></loader>
		<div class="user" @click="logout" v-else>
			<div class="name">{{ user.name }}</div>
			<div class="email">{{ user.email }}</div>
		</div>
	</div></div>
	<div class="navbar mobile fixednav"><div class="wrapper-full">
		<div class="logo" @click="home">
			<img :src="img.logoWhite">
		
		</div>
		<div class="spacer"></div>
	

		<loader v-if="loading" :simple="true"></loader>
		
			
			 <v-btn @click="logout" text color="#ffffff">
        {{ user.name }}<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z" />
</svg>
      </v-btn>
			
		
	</div></div>
		<!--< <div class="alert" style="margin-top: 70px;"><div class="wrapper-full" style="display: block;text-align: center;">
span>We will soon have to add VAT to our price list, click here to <a href="https://washcycle.co.uk/vat-message/">read more </a></span></div>

	</div> 	-->
	<div class="content" >
		<router-view></router-view>
	</div>

	<footer-view></footer-view>
	 <v-bottom-navigation
	app
   	small
	fixed
	class="mobile center"
    color="#01BAD4"
  >
    <v-btn @click="home" value="home">
      <span>Orders</span>
      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21,19V17H8V19H21M21,13V11H8V13H21M8,7H21V5H8V7M4,5V7H6V5H4M3,5A1,1 0 0,1 4,4H6A1,1 0 0,1 7,5V7A1,1 0 0,1 6,8H4A1,1 0 0,1 3,7V5M4,11V13H6V11H4M3,11A1,1 0 0,1 4,10H6A1,1 0 0,1 7,11V13A1,1 0 0,1 6,14H4A1,1 0 0,1 3,13V11M4,17V19H6V17H4M3,17A1,1 0 0,1 4,16H6A1,1 0 0,1 7,17V19A1,1 0 0,1 6,20H4A1,1 0 0,1 3,19V17Z" />
</svg>
    </v-btn>

    <v-btn @click="profile" value="profile">
      <span>Profile</span>
      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7.07,18.28C7.5,17.38 10.12,16.5 12,16.5C13.88,16.5 16.5,17.38 16.93,18.28C15.57,19.36 13.86,20 12,20C10.14,20 8.43,19.36 7.07,18.28M18.36,16.83C16.93,15.09 13.46,14.5 12,14.5C10.54,14.5 7.07,15.09 5.64,16.83C4.62,15.5 4,13.82 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,13.82 19.38,15.5 18.36,16.83M12,6C10.06,6 8.5,7.56 8.5,9.5C8.5,11.44 10.06,13 12,13C13.94,13 15.5,11.44 15.5,9.5C15.5,7.56 13.94,6 12,6M12,11A1.5,1.5 0 0,1 10.5,9.5A1.5,1.5 0 0,1 12,8A1.5,1.5 0 0,1 13.5,9.5A1.5,1.5 0 0,1 12,11Z" />
</svg>
    </v-btn>

    <v-btn class="TrackNewOrder" @click="order" value="Neworder">
      <span>New Order</span>
     <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
</svg>
    </v-btn>
  </v-bottom-navigation>
</section>

</template>

<script>
const DEV = process.env.NODE_ENV !== "production";
import logoWhite from "../assets/logo-white.png";
import FooterView from "../components/Footer.vue";
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
export default {
	components: {
		FooterView
	},

	data() {
		return {

			img: { logoWhite }, // All images
			Orderscreen: false,
			loading: true, // Loading data from API

			user: {
				name: null,
				email: null
			},

			alert: {
				text: null,
				url: null
			}
		}
	},

    created() {
        this.$api.get("/user").then(res => {
            this.user = res.data;
            this.loading = false;
        }).catch(error => {
            this.$router.push("/welcome");
        });
    },

	methods: {
		home() {
			this.Orderscreen = false;
			this.$router.push("/");

		},

		order() {
			// Record GTM events 
			if (!DEV) {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					'event': 'Order Started'
				});
			}

			this.Orderscreen = true;
			this.$router.push("/order");
		},

		profile() {
			this.Orderscreen = false;
			this.$router.push("/profile");
		},

		billing() {
			this.Orderscreen = false;
			this.$router.push("/billing");
		},

		logout() {
			if (window.confirm("Are you sure you wish to logout?")) {
				this.$api.get("/user/logout").then(res => {
					this.$router.push("/welcome");
				});
			}
		}
	},

	computed: {
		onOrderPage() {
			return this.$route.path === "/order";
		}
	}
}
</script>

<style lang="less" scoped>
@mobile: ~'(min-width: 100px)';

section.main {
	.center {
		text-align: center !important;
	}

	.order-button {
		bottom: 80px;
	}

	.content {
		@media @mobile {
			padding-top: 65px;
		}
	}

	.v-btn__content {

		@media @mobile {
			font-size: 2.5vmin
		}

		;
	}

	.mobile {
		display: none;


		@media @mobile {
			display: initial;

		}
	}

	.desktop {
		display: initial;

		@media @mobile {
			display: none;
		}
	}


	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.content {
		flex: 1;
	}

	.alert {
		background-color: #01BAD4;
		color: #FFF;
		font-size: 14px;
		padding: 14px 0;


		.wrapper-full {
			display: flex;
			align-items: center;
			justify-content: space-between;

		}

		a {
			color: #FFF;
		}
	}

	.fixednav {
		position: fixed;
		z-index: 100;
	}

	.navbar {

		background-color: #081F3F;
		color: #FFF;

		box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.2);

		.wrapper-full {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 90px;

			@media @mobile {
				height: 70px;
				width: 100vw;
				margin: 0;
			}

		}

		.logo {
			display: flex;
			align-items: center;
			cursor: pointer;
			margin-right: 20px;

			img {
				height: auto;
				width: 75px;

				@media @mobile {
					width: 45px;
				}
			}

			.title {
				font-size: 18px;
				margin-left: 12px;
			}

			.beta {
				color: #01BAD4;
				margin-left: 8px;
				text-transform: uppercase;
				border: solid 1px #01BAD4;
				font-size: 12px;
				padding: 3px 8px;
				border-radius: 50px;
			}
		}

		.link {
			margin: 0 10px;
			color: rgba(255, 255, 255, 0.8);
			cursor: pointer;

			&:hover {
				color: rgba(255, 255, 255, 0.9);
			}

			&:active {
				color: rgba(255, 255, 255, 1);
			}
		}

		.spacer {
			flex-grow: 1;
		}

		.button {
			padding: 8px 18px;
			margin-right: 20px;
			color: #2ecc71;
			text-transform: uppercase;
			font-size: 14px;
			border: solid 1px #2ecc71;
			border-radius: 100px;
			cursor: pointer;

			&:hover {
				background-color: rgba(46, 204, 113, 0.2);
			}

			&:active {
				background-color: rgba(46, 204, 113, 0.4);
			}
		}

		.user {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			cursor: pointer;

			.email {
				font-size: 14px;
				margin-top: 1px;
				color: rgba(255, 255, 255, 0.6);
			}
		}
	}
}
</style>

