<template lang="html">
<section class="billing"><div class="wrapper-full">
	<h1>Billing</h1>

	<loader name="your cards" v-if="loading"></loader>
	<div class="cards" v-else>
		<div class="card new" @click="showNewCard = true">Add a new card</div>

		<div class="card" v-for="card in cards">
			<div class="meta">
				<div class="brand">{{ card.brand }}</div>
				<div class="number">•••• •••• •••• {{ card.last4 }}</div>
				<div class="expiry">{{ card.expiry_string }}</div>
			</div>

			<div class="actions">
				
				<div class="action" @click="remove(card)">Remove</div>
			</div>
		</div>

		<div class="block" v-if="processing"></div>
	</div>

	<new-card v-if="showNewCard" @close="close"></new-card>
</div></section>
</template>

<script>
import NewCard from "../components/NewCard";

export default {
	components: {
		NewCard
	},

	data() {
		return {
			loading: true,
			processing: false,
			cards: [],
			default_source: null,

			showNewCard: false
		}
	},

	created() {
		this.reload();
	},

	methods: {
		reload() {
			this.$api.get("/user/cards").then(res => {
				this.loading = false;
				this.processing = false;
				
				this.cards = res.data.cards;
				
			});
		},

		close() {
			this.showNewCard = false;
			this.reload();
		},

	
		remove(card) {
			let confirm = window.confirm(`Are you sure you wish to delete the ${card.brand} ending in ${card.last4}?`);

			if (confirm) {
				this.processing = true;
				this.$api.delete("/card/" + card.id, { redirect: false }).then(res => {
					if (res.status !== 200) window.alert(res.data);
					this.reload();
				});
			}
		}
	}
}
</script>

<style lang="less" scoped>
@mobile: ~'(max-width: 1100px)';
section.billing {
	.cards {
		display: flex;
		position: relative;
		flex-wrap: wrap;

		// Processing block display
		.block {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-color: rgba(255, 255, 255, 0.5);
		}

		.card {
			text-align: center;
			border: solid 1px #DDD;
			border-radius: 4px;
			overflow: hidden;
			margin: 10px;
	@media @mobile {
width: 100%;}
			.meta {
				padding: 20px 40px;
				font-size: 18px;
			}

			.actions {
				padding: 10px;
				background-color: #FAFAFA;
				border-top: solid 1px #DDD;
				text-align: left;

				.action {
					display: inline-block;
					margin: 10px;
					color: #01BAD4;
					cursor: pointer;

					&:hover { text-decoration: underline; }
				}
			}

			&.new {
				padding: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #888;
				border: dashed 1px #888;
				cursor: pointer;
	@media @mobile {
width: 100%;}
				&:hover {
					color: #01BAD4;
				}
			}
		}
	}
}
</style>
