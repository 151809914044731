<template lang="html">
<div id="app">
	<router-view></router-view>
</div>
</template>

<script>
export default {

}
</script>

<style lang="less">
@mobile: ~'(max-width: 1100px)';

body {
	font-family: "Montserrat", sans-serif;
	background-color: #FFF;
	color: #151515;
}

.wrapper-full {
	width: 1100px;
	padding: 0 20px;
	box-sizing: border-box;
	margin: 0 auto;
       @media	@mobile{
	width:95vw;
	display:block;
	padding: 0 20px;
       } 	 
}

h1 {
	font-weight: 300;
	font-size: 28px;
	padding: 20px 0;
	margin: 0;
}
</style>
