<template lang="html">
<section class="dashboard"><div class="wrapper-full">
	<div class="popup-overlay" v-if="popupVisible">
  <div class="popup">
    <div class="popup-content">
      <h1>Welcome to Wash Cycle!</h1>
			<div class="popup-text">
      <p>First things first: If this is your first time with us, we recommend heading to the 'Profile' tab at the bottom of the page and adding your collection address and your payment card before starting the order process! This will save your information and enable a more speedy checkout process!</p>
      <br>
			<p>Next step: To speed up the order process even more, click 'Wash Preferences' and save your default favorite wash and dry settings, and choose your favorite detergents and softeners!</p>
      <br>
			<p>Finally: Click New Order or New Bundle and follow through the steps to complete your ideal laundry collection!</p>
      <br>
			<p>Use code <b>'#LaundryDay'</b> at checkout to receive 10% Off! <i>One-Time Use Only</i></p>
      <br>
			<p>We look forward to meeting you!</p>
			<br>
      <p>The Wash Cycle Team</p></div>
					<br>
         <label for="dontShowAgain">
        <input type="checkbox" id="dontShowAgain" v-model="dontShow"> Don't show again
      </label>
			<br>
      <button class="button"@click="closePopup">Close</button>
    </div>
  </div>
</div>
		<messages-view></messages-view>
		<orders-view></orders-view>
	<bundles-view></bundles-view>
	
</div></section>
</template>

<script>
import OrdersView from "./Orders";
import MessagesView from "./Messages";
import BundlesView from "./Bundles";

export default {
	components: {
		OrdersView,
		MessagesView,
		BundlesView
	},
	data() {
		return {
			popupVisible: false,
			dontShow: false,
		}
	},
	created() {
		this.reload();
	},
	methods: {
		reload() {
			this.$api.get("/user/popup").then(res => {
				this.popupVisible = res.data;
			});
		},

		
		closePopup() {
			if (this.dontShow === true) {
				this.$api.post("/user/popup").then(res => {
					this.popupVisible = res.data;
				});
			}
			this.popupVisible = false;
		},
	},
}
</script>

<style lang="less">
section.dashboard {
	.wrapper-full{width:100vw;}
	overflow: auto;

	.header {
		font-weight: 500;
		letter-spacing: 0.05rem;
		font-size: 18px;
		text-transform: uppercase;

		margin-top: 20px;
	}

	.new {
		display: flex;
		flex-direction: column;
		align-items: center;

		text-align: center;
		border-radius: 4px;
		border: solid 1px #DDD;
		padding: 50px 20px;
		margin: 10px 0;

		.image {
			width: 80px;
			height: 80px;
		}

		.title {
			font-size: 20px;
			font-weight: 500;
			margin-top: 20px;
		}

		.subtitle {
			margin-top: 14px;
			color: #888;
			font-weight: 300;
			max-width: 400px;
			line-height: 1.5rem;
		}

		.button {
			margin-top: 20px;
			border-radius: 4px;
			font-size: 14px;
			background-color: #DDD;
			color: #FFF;
			padding: 14px 30px;
			cursor: pointer;

			&.green { background-color: #2ecc71; }
			&.blue { background-color: #01BAD4; }

			&:hover { opacity: 0.8; }
			&:active { opacity: 0.6; }
		}
	}
		.popup-overlay {
	position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		justify-content: center;
		align-items: center;
		z-index: 999;
		overflow-y: auto;
		padding: 20px;
		}
	
		.popup {
			background-color: white;
			border-radius: 5px;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
		}
	
		.popup-content {
			text-align: center;
		}
	
		.popup button {
			margin-top: 10px;
		}
				.popup-text {
					text-align: justify;
						display: block;
						width: 80%;
						margin: auto;
				}
		.popup .button {
		text-align: center;
		padding: 10px;
		width:90% ;
		border: solid 1px #DDD;
		border-radius: 4px;
		margin: 10px;
}
}
</style>
