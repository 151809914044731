<template lang="html">
<section class="slots">
	<div class="header">Choose a collection slot</div>

	<div class="warning" v-if="limit">Due to our carrying capacity, slots with fewer than {{ limit > maxSpaces ? maxSpaces : limit }} {{ limit === 1 ? "space" : "spaces" }} have been disabled</div>

	<loader v-if="loading" name="collection slots"></loader>
	<div class="container" v-else>
		<div class="day" v-for="day in parseDays">
			<div class="relative">{{ day.relative }}</div>
			<div class="absolute">{{ day.absolute }}</div>

			<div class="slot" v-for="slot in day.slots" :class="{ disabled: slot.disabled, selected: (slot.start === selected.start && slot.end === selected.end) }" @click="setSlot(slot, day)">
				<div class="time">{{ slot.time_string }}</div>
				<div class="spaces" :class="{ good: slot.spaces >= 4, bad: slot.spaces <= 2 }">{{ slot.spaces }} space{{ slot.spaces > 1 ? "s" : null }} left</div>
				<div class="close" v-if="slot.close_string">closes {{ slot.close_string }}</div>
			</div>
		</div>
	</div>

	<div class="notification" v-if="!loading && !isUpgraded">All laundry orders will be delivered between <b>13:00 - 18:00</b> on the day they are collected.<br><b> Any orders 14Kg+ in size will possibly require a next day return</b></div>
	<div class="notification" v-if="!loading && isUpgraded">All orders orders will be returned the followimg day in the same time slot</b></div>
</section>
</template>

<script>
import moment from "moment";

export default {
	props: [
		"value",
		"limit",
		"isUpgraded"
	],

	data() {
		return {
			loading: true, // Whether loading AJAX data
			days: [], // All the slot information for different days
			selected: this.value,
			reloadSeconds: 30,
			maxSpaces: 5 // NOTE: This should not be hardcoded, fix it later
		}
	},

	created() {
		this.reload();
	},

	watch: {
		limit(val) {
			this.reset();
		}
	},

	methods: {
		setSlot(slot, day) {
			if (!slot.disabled) {
				this.selected = slot;
				this.$emit("input", slot);
			}
		},

		reset() {
			this.selected = {
				start: null,
				end: null
			};

			this.$emit("input", this.selected);
		},

		reload() {
			this.$api.get("/slots?days=14", {
				params: {
					user: this.user
				}
			}).then(res => {
				this.loading = false;
				this.days = res.data;

				window.setTimeout(() => this.reload(), this.reloadSeconds * 1000);
			});
		}
	},

	computed: {
		parseDays() {
			let clone = obj => JSON.parse(JSON.stringify(obj));
			let days = clone(this.days);

			days.map(day => {
				day.slots.map(slot => {
					if (slot.spaces < this.limit && slot.spaces !== this.maxSpaces) slot.disabled = true;
					if (slot.spaces < this.limit && slot.spaces !== this.maxSpaces) slot.disabled = true;
					return slot;
				});

				return day;
			});

			return days;
		}
	}
}
</script>

<style lang="less" scoped>
section.slots {
	.ironing {
		margin: 20px 0;
		padding: 14px;
		border-radius: 4px;
		border: solid 1px #DDD;
		line-height: 1.4;
		text-align: center;
	}

	.container {
		display: flex;
		text-align: center;
		overflow: scroll;
	}

	.day {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		margin: 5px;
	}

	.relative {
		font-size: 20px;
	}

	.absolute {
		margin-top: 3px;
		margin-bottom: 10px;
		color: #888;
	}

	.slot {
		padding: 10px 28px;
		margin: 5px;
		border: solid 1px #DDD;
		border-radius: 4px;
		cursor: pointer;

		.time {
			font-size: 16px;
		}

		.close {
			color: #e67e22;
			font-size: 12px;
			margin-top: 5px;
		}

		.spaces {
			font-size: 12px;
			margin-top: 3px;

			&.good {
				color: #2ecc71;
			}

			&.bad {
				color: #e74c3c;
			}
		}

		&.selected {
			color: #01BAD4;
			border: solid 1px #01BAD4;
		}

		&.disabled {
			opacity: 0.5;
			color: #888;
			cursor: default;

			& .spaces {
				display: none;
			}

			& .close {
				display: none;
			}
		}
	}
}
</style>
