<template lang="html">
<section class="user">


<div class="">
	<h1>Your Referrals</h1>
 <div>
	<p class="text">You succesfully referred <span style="color: #01bad4;"><b>{{ this.refereal.tokens }}</b></span>, refer more people to get <span style="color: #01bad4;"><b>5%</b></span> off your next order</p>
</div>
<div style="padding: 10px;">
    <span style="display:block;padding-right: 50px;">Your Referral Code: </span>
    <input v-model="refereal.referral_code" :placeholder="refereal.referral_code" class="details-input" id="referralCodeInput" readonly>
    <span class="pill selected button" @click="copyToClipboard()">Copy</span>
</div>
<p class="text" v-if="refereal.is_blocked">Refferal code already entered:</p>
<p class="text" v-else>If you got a referral code from somebody, enter it below:</p>
<div class="email" style="padding: 10px;"><span v-if="!refereal.is_blocked" style="display:block;padding-right: 50px;">Referred Code: </span><input v-model="newrefereal.code" class="details-input" :placeholder="refereal.referring_code" :readonly="refereal.is_blocked" ></div>
<div v-if="!refereal.is_blocked" @click="savechanges()" style="text-align:right;"><span class="pill selected button">Save Changes</span></div>
<div v-if="!refereal.is_blocked" @click="cancel()" style="text-align:right;"><span class="pill button">Cancel</span>

</div>
<pass-modal v-if="showmodal" :user="user" @close="showmodal = false"></pass-modal>	
</div>	
</section>
</template>

<script>
export default {

	data() {
		return {
			loading: true,
			processing: false,
			showmodal: false,
			refereal: {
				id: null,
				user_id: null,
				referral_code: null,
				referring_code: null,
				referred_users: [],
				tokens: 0,
				has_ordered: null
			},
			user: {
				name: null,
				email: null,
				id: null
			},
			newuser: {
				name: null,
				email: null,
				id: null
			},
			newrefereal: {
			code:null,
			},
			showNewCard: false
		}
	},

	created() {
		this.$api.get("/user").then(res => {
		this.user = res.data;
		});
		this.$api.get("/referral").then(res => {
		this.refereal = res.data;
		this.loading = false;

			});
	},

	methods: {
		reload() {
		},
		showpasschange() {
			this.showmodal = true;
		},
		cancel() {
			this.$router.push("/");
		},
		 copyToClipboard() {
	var copyText = document.getElementById("referralCodeInput");
	copyText.select();
	document.execCommand("copy");
},
		savechanges() {
			if (this.newrefereal.code === this.refereal.referral_code) {
				window.alert("You can't use your own code.");
				throw new Error(`You can't use your own code.`);
			}
			if (!this.newrefereal.code) {
				window.alert("Referred code cannot be empty");
				throw new Error(`Referred code cannot be empty.`);
			
			}
			let strippedString = this.newrefereal.code.replace(/[^\w]/g, '');
				if (!strippedString) {
				window.alert("Referred code cannot be empty");
				throw new Error(`Referred code cannot be empty.`);
			}
			const code = this.newrefereal.code.toString();
			this.$api.post("/referral/updatecode", { refferedCode: code })
				.then(res => {
					if (res.status === 200) {
						window.alert("Referral code updated successfully");
					} else {
						window.alert(`${res.data.message}`);
					}
				})
				.catch(error, res => {
					window.alert(`${res.data.message}`);
				});
		},
		setcategory(i) {
			this.category = i;

		},
		close() {

		},



	}
}
</script>

<style lang="less" scoped>
@mobile: ~'(max-width: 1100px)';

section.user {
	.details-input {
		padding: 10px;
		margin-top: 10px;
		color: #01bad4;
		font-size: 14px;
		border: solid 1px #01bad4;
		border-radius: 7px;
	}

	.pill {
		padding: 8px 18px;
		margin-right: 20px;
		color:
			#01bad4;
		text-transform: uppercase;
		font-size: 14px;
		border: solid 1px #01bad4;
		width: 27%;
		display: inline-block;
		margin: 10px;
		border-radius: 100px;
		cursor: pointer;
		text-align: center;

		@media @mobile {
			display: block;
			margin-left: -20px;
			text-align: center;
			width: 100%;

		}

		.email span {
			display: inline-block;

			@media @mobile {
				display: block;


			}
		}

		.email {

			@media @mobile {
				display: block;


			}
		}
	}

	.selected {
		background: #01bad4;
		color: white;
	}
.text{padding: 10px;}

}</style>
