<template lang="html">
  <section class="new-address">
    <div class="container">
      <div class="dialog">
        <form @submit.prevent="submit" method="POST" action="#">
          <div class="header">
            <p>Add a new address</p>
            <span @click="close">&times;</span>
          </div>

          <div class="input-group">
            <div>
              <label class="required">Name</label>
              <input
                size="30"
                type="name"
                v-model="address.name"
                placeholder="John Smith"
              />

              <label class="required">Phone number</label>
              <input
                type="phone"
                v-model="address.phone"
                placeholder="+44 9742 83771"
              />

              <label class="what3words-label">
                <span>what3words</span>
                <span class="badge">new</span>
              </label>
              <what3words-autosuggest @click="reconcileAutosuggest">
                <input type="text" v-model="w3w" ref="autosuggest" />
              </what3words-autosuggest>
            </div>

            <div>
              <label class="required">Address Line 1</label>
              <input
                size="30"
                type="text"
                v-model="address.address_line_1"
                placeholder="13 Greenway Road"
              />

              <label class="required">Address Line 2</label>
              <input
                type="text"
                v-model="address.address_line_2"
                placeholder="Rumney"
              />

              <label>Address Line 3</label>
              <input
                type="text"
                v-model="address.address_line_3"
                placeholder=""
              />

              <label class="required">City</label>
              <input type="text" v-model="address.city" placeholder="Cardiff" />

              <label>County</label>
              <input type="text" v-model="address.county" />

              <label class="required">Postcode</label>
              <input
                type="text"
                v-model="address.postcode"
                placeholder="CF3 3HJ"
              />
            </div>
          </div>

          <button class="submit" :class="{ ready }">
            {{ processing ? "Please wait..." : "Add new address" }}
          </button>
        </form>
      </div>

      <div class="background" @click="close"></div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      address: {
        name: "",
        phone: "",
        address_line_1: "",
        address_line_2: "",
        address_line_3: "",
        city: "",
        county: "",
        postcode: "",
      },

      w3w: "",

      processing: false,
    };
  },

  methods: {
    close() {
      this.$emit("close");
    },

    reconcileAutosuggest() {
      this.w3w = this.$refs.autosuggest.value;
    },

    async submit() {
      if (this.ready) {
        this.processing = true;

        try {
          if (this.w3w) {
            const res = await what3words.api.convertToCoordinates(this.w3w);
            console.log(res);
          }
        } catch (err) {
          console.error(err);
        }

        this.$api
          .post("/user/address", this.address, { redirect: false })
          .then((res) => {
            this.processing = false;
            if (res.status === 201) this.close();
            else window.alert(res.data);
          });
      }
    },
  },

  computed: {
    ready() {
      if (this.processing) return false;

      let required = [
        "name",
        "phone",
        "address_line_1",
        "address_line_2",
        "city",
        "postcode",
      ];
      let ready = true;

      for (let i = 0; i < required.length; i++) {
        let field = this.address[required[i]];
        if (field.length < 2) ready = false;
      }

      return ready;
    },
  },
};
</script>

<style lang="less" scoped>
section.new-address {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  z-index: 999;

  .input-group {
    display: flex;
    width: 100%;

    & > div {
      padding: 20px;
      padding-bottom: 0px;

      &:first-of-type {
        border-right: solid 1px #ddd;
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: 0;
    top: 0;
    padding: 20px;
    box-sizing: border-box;
    min-width: 100%;
    min-height: 100%;
  }

  .background {
    background-color: rgba(0, 0, 0, 0.5);

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .what3words-label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .badge {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      background-color: #3498db;
      color: #fff;
      padding: 4px 4px;
      margin-left: 6px;
      border-radius: 4px;
    }
  }

  .dialog {
    background-color: #fff;
    padding: 20px;
    z-index: 11;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    max-width: 100%;
  }

  label,
  input {
    display: block;
  }

  input {
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #ddd;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 14px;
    margin-top: 4px;
  }

  .submit {
    background-color: #aaa;
    color: #fff;
    border-radius: 4px;
    border: none;
    padding: 10px;
    margin-top: 15px;
    cursor: pointer;
    width: 100%;

    &.ready {
      background-color: #2ecc71;
      opacity: 0.8;
      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 1;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 600;
    }

    span {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
      border-radius: 9999px;
      font-size: 32px;
      line-height: 1;
      align-self: flex-start;

      &:hover {
        background-color: #eee;
      }
    }
  }

  .required::after {
    content: " *";
    color: #e74c3c;
    font-weight: 500;
  }
}
</style>
