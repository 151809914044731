<template lang="html">
<section class="welcome">
	<div class="left panel" :style="'background-image: url(' + img.welcome + ')'"><div class="wrapper">
		
	</div></div>

	<div class="right panel"><div class="wrapper">
		<div class="toggles" @click="toggle">
			<div class="toggle" :class="{ active: register }">Sign up</div>
			<div class="toggle" :class="{ active: login }">Log in</div>
		</div>
		<div class="logo-wrapper">
		<div class="logo-headline"><div class="logoimg">
		<img class="logo" :src="img.logoWhite"></div>
		<div class="headertitle"><span class="spantitle">WashCycle</span><span class="subtitle">Delivery Laundry Service</span></div></div>

		
</div>
		<div class="login" v-if="login && !forgot">
			<!-- <div class="header">Log in to Wash Cycle</div> -->
			<div class="warning" v-if="warning">{{ warning }}</div>

			<form @submit.prevent="submit" action="#" method="POST">
				<label>Email address</label>
				<input type="email" placeholder="john.smith@gmail.com" v-model="user.email">

				<label>Password</label>
				<input type="password" placeholder="•••••••••" v-model="user.password">
				<span class="forgot" @click="forgotpass">Forgot Password</span>
				<input type="submit" :value="processing ? 'Logging in...' : 'Log in'" :class="{ disabled: !loginReady || processing }">
			</form>

		</div>
				<div class="forgot" v-if="forgot">
			<!-- <div class="header">Log in to Wash Cycle</div> -->
		
			<form @submit.prevent="submit" action="#" method="POST">
				<label>Email address</label>
				<input type="email" placeholder="john.smith@gmail.com" v-model="user.email">
				<input type="submit" :value="processing ? 'Reset' : 'Reset'" :class="{ disabled: !loginReady || processing }">
			</form>

		</div>

		<div class="signup" v-if="register">
			<!-- <div class="header">Sign up to Wash Cycle</div> -->
			<div class="warning" v-if="warning">{{ warning }}</div>

			<form @submit.prevent="submit" action="#" method="POST">
				<label>Full name</label>
				<input type="name" placeholder="John Smith" v-model="user.name">

				<label>Email address</label>
				<input type="email" placeholder="john.smith@gmail.com" v-model="user.email">

				<label>Password</label>
				<input type="password" placeholder="•••••••••" v-model="user.password">

				<label>Confirm password</label>
				<input type="password" placeholder="•••••••••" v-model="user.confirmPassword">
				<label>Postcode</label>
				<input  placeholder="CF24 3AR" v-model="user.postcode">
				<div class="postcodeWarning" v-if="postcodeWarning">{{ postcodeWarning }}</div>
				<input type="submit" :value="processing ? 'Signing up...' : 'Sign up'" :class="[{ disabled: !signupReady || processing },'TrackSignup']">
			</form>
		</div>

		<div class="mask" v-if="processing"></div>
	</div><div class="toggles2" @click="toggle">
			<div class="toggle2" :class="{ active: register }">Sign up</div>
			<div class="toggle2" :class="{ active: login }">Log in</div>
		</div></div>
</section>
</template>

<script>
const DEV = process.env.NODE_ENV !== "production";
import welcome from "../assets/welcome.png";
import logoWhite from "../assets/logo-white.png";

export default {
	data() {
		return {
			img: { welcome, logoWhite },
			login: true,
			register: false,
			forgot: false,
			processing: false,

			user: {
				email: "",
				password: "",
				postcode: "",
				name: "",
				confirmPassword: ""
			},

			warning: null,
			postcodeWarning: null
		}
	},

	methods: {
		home() {
			this.$router.push("/");
		},
		forgotpass() {
			this.forgot = true;
			this.login = false;
			this.register = false;

		},
		toggle() {
			let existingMetaTag = document.querySelector('meta[name="description"]');
			this.warning = null;
			if (this.login) {
				this.warning = null;
				this.login = false;
				this.forgot = false;
				this.register = true;
				document.title = "Wash Cycle - Delivery Laundry Service, Cardiff - Signup";
				existingMetaTag.setAttribute('content', "We're a new student laundry collection and delivery service just opened in Cardiff! - Signup");

			}
			else if (!this.login && this.register) {
				this.warning = null;
				this.login = true;
				this.forgot = false;
				this.register = false;
			document.title = "Wash Cycle - Delivery Laundry Service, Cardiff - Login";
			existingMetaTag.setAttribute('content', "We're a new student laundry collection and delivery service just opened in Cardiff! - Login");
			}
			else if (!this.login && !this.register) {
				this.warning = null;
				this.login = true;
				this.forgot = false;
				this.register = false;
			document.title = "Wash Cycle - Delivery Laundry Service, Cardiff";
			existingMetaTag.setAttribute('content', "We're a new student laundry collection and delivery service just opened in Cardiff!");
			}




			this.$router.push({
				query: {
					ref: (this.login ? "login" : "signup")
				}
			});
		},

		submit() {
			this.warning = null;

			if (this.login) {
				if (this.loginReady) {
					this.processing = true;

					this.$api.post("/user/login", this.user, { redirect: false }).then(res => {
						this.processing = false;

						if (res.status !== 200) this.warning = res.data;
						else this.home();
					});
				}
			} else if (this.register) {
				if (this.signupReady) {
					this.processing = true;
					this.$api.post("/user/signup", this.user, { redirect: false }).then(res => {
						this.processing = false;
						if (!DEV) {
							window.dataLayer = window.dataLayer || [];
							window.dataLayer.push({
								'event': 'User Created'
							});
						}

						if (res.status !== 201) this.warning = res.data;
						else {
							this.toggle();
							this.submit();
						}
					});
				}
			}
			else if (this.forgot) {
				this.processing = true;

				this.$api.post("/user/forgot", this.user, { redirect: false }).then(res => {
					this.processing = false;

					if (res.status !== 201) this.warning = res.data;
					if (res.status == 200) {
						alert("Please check your inbox")
						this.login = true;
						this.toggle();
						this.toggle();

					}
				});

			}
		}
	},

	created() {
		let existingMetaTag = document.querySelector('meta[name="description"]');
		this.warning = null;
		if (this.login) {
			this.warning = null;
			this.login = false;
			this.forgot = false;
			this.register = true;
			document.title = "Wash Cycle - Delivery Laundry Service, Cardiff - Signup";
			existingMetaTag.setAttribute('content', "We're a new student laundry collection and delivery service just opened in Cardiff! - Signup");

		}
		else if (!this.login && this.register) {
			this.warning = null;
			this.login = true;
			this.forgot = false;
			this.register = false;
			document.title = "Wash Cycle - Delivery Laundry Service, Cardiff - Login";
			existingMetaTag.setAttribute('content', "We're a new student laundry collection and delivery service just opened in Cardiff! - Login");
		}
		else if (!this.login && !this.register) {
			this.warning = null;
			this.login = true;
			this.forgot = false;
			this.register = false;
			document.title = "Wash Cycle - Delivery Laundry Service, Cardiff";
			existingMetaTag.setAttribute('content', "We're a new student laundry collection and delivery service just opened in Cardiff!");
		}
		// Check if already logged in and redirect
		this.$api.get("/user", { redirect: false }).then(res => {
			if (res.status !== 401) this.home();
		});

		// Check the tab url ref
		let { ref } = this.$route.query;

		if (ref === "signup") {
			this.login = false;
			this.forgot = false;
			this.register = true;
			document.title = "Wash Cycle - Delivery Laundry Service, Cardiff - Signup";
		}
		if (ref === "login") {
			this.login = true;
			this.forgot = false;
			this.register = false;
			document.title = "Wash Cycle - Delivery Laundry Service, Cardiff - Login";
		}

	},

	computed: {
		loginReady() {
			let { user } = this;
			let ready = true;

			if (user.email.length < 5) ready = false;
			if (user.password.length < 4) ready = false;

			return ready;
		},

		signupReady() {

			let { user } = this;
			let ready = true;
			let validPostcodes = ["cf24", "cf23", "cf3", "cf95", "cf99", "cf91", "cf10", "cf11", "cf30", "cf14", "cf64", "cf5", "cf5 ", "cf3 "];
			let firstFour = user.postcode.substring(0, 4).toLowerCase();
			if (user.postcode.length > 4 && !validPostcodes.includes(firstFour)) {
				this.postcodeWarning = "Sorry we don't operate in your area yet."
				ready = false;
			} else this.postcodeWarning = null;
			if (!validPostcodes.includes(firstFour)) ready = false;
			if (!this.loginReady) ready = false;
			if (user.name < 3) ready = false;
			if (user.postcode < 3) ready = false;
			if (user.password !== user.confirmPassword) ready = false;
			return ready;
		}
	}
}
</script>

<style lang="less" scoped>
@mobile: ~'(max-width: 1100px)';

section.welcome {
	.forgot {
		font-size: 14px;
		cursor: pointer;
		color: #24bbd4;
	}

	overflow-x: hidden;
	display: flex;
	color: #FFF;
	min-width: 1100px;

	@media @mobile {
		min-width: 100px;
		min-width: 10px;
	}

	.panel {
		width: 50%;
		display: flex;
		min-height: 100vh;
		flex-direction: column;

		@media @mobile {
			width: 100%;
		}
	}

	.wrapper {
		width: 550px;

		padding: 20px 20px;

		@media @mobile {
			width: 100%;
		}
	}

	.left {
		background-color: #151515;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		align-items: flex-end;

		@media @mobile {
			display: none;
		}
	}

	.logo {
		width: 55px;
		width: 10vmin;

		height: auto;

		@media @mobile {
			width: 20vmin;

		}
	}

	.right {
		background-color: #191c20;
	}

	.logoimg {
		display: block;
		margin: auto;
		text-align: center;
	}

	.logoimg img {
		font-size: 2.8vmin;
		height: auto;
		margin: auto;
	}

	.logo-headline {
		margin: auto;
		display: block;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}

	.mask {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #191c20;
		opacity: 0.5;
	}

	.warning {
		background-color: rgba(231, 76, 60, 0.1);
		color: #e74c3c;
		border: solid 1px #e74c3c;
		padding: 20px;
		border-radius: 4px;
	}

	.postcodeWarning {
		background-color: rgba(231, 76, 60, 0.1);
		color: #e74c3c;
		border: solid 1px #e74c3c;
		padding: 20px;
		border-radius: 4px;
	}

	.logo-wrapper {
		height: 30vh;
	}

	.headertitle {

		display: block;
		font-size: 5vmin;
		font-weight: 500;
		line-height: 1 !important;
		letter-spacing: 0.02em !important;
		font-family: Roboto, sans-serif !important;
		color: white;
		width: 100%;
		text-align: center;

		@media @mobile {
			font-size: 8vmin;
		}


	}

	.subtitle {

		display: block;

		bottom: 6px;
		left: 105px;
		font-size: 10px;
		font-size: 2vmin;
		color: #00C1D5;
		font-family: Montserrat, sans-serif;
		letter-spacing: 0;
		padding-top: 8px;

		@media @mobile {
			font-size: 3vmin;
		}
	}

	.spantitle {
		@media @mobile {}

	}

	.toggles2 {
		white-space: nowrap;
		display: inline-flex;
		bottom: 0;
		flex-grow: 1;
	}

	.toggle2 {
		display: none;
		padding: 12px 20px;
		height: auto;
		margin-top: auto;
		font-size: 14px;
		text-align: center;
		border-radius: 20px;
		color: rgba(255, 255, 255, 0.7);

		&.active {
			background-color: #01bbd4;
			color: #FFF;
		}

		@media @mobile {
			display: inline-block;
			width: 50%;
			border-radius: 20px;
			font-size: 4.7vmin;
			text-align: center;
		}
	}

	.toggles {
		margin-left: auto;
		display: flex;
		overflow: hidden;
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 100px;
		box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
		cursor: pointer;
		width: 178px;

		@media @mobile {

			display: none;
		}

		.toggle {
			padding: 12px 20px;
			font-size: 14px;
			color: rgba(255, 255, 255, 0.7);

			&.active {
				background-color: #01bbd4;
				color: #FFF;
			}
		}
	}

	.login,
	.signup {

		margin: auto;
		font-size: 2.7vmin;
		padding-left: 20px;
		padding-right: 20px;
		position: relative;

		@media @mobile {
			font-size: 4.7vmin;
			margin-bottom: 30px;
			margin-top: 50px;
		}

	}

	// .header {
	// 	font-size: 24px;
	// 	letter-spacing: 0.08rem;
	// 	font-weight: 100;
	// 	text-align: center;
	// }

	input,
	label {
		color: #FFF;
		display: block;
	}

	label {
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: 0.08rem;
		margin-top: 20px;
	}

	input:focus {
		outline: none;
	}

	input:not([type="submit"]) {
		margin-top: 5px;
		padding: 10px 0;
		width: 100%;
		background-color: transparent;
		border: none;
		border-bottom: solid 1px #FFF;

		&:focus {
			border-bottom: solid 1px #01bbd4;
		}
	}

	input[type="submit"] {
		margin-top: 50px;
		position: relative;
		width: 100%;
		padding: 14px;
		border-radius: 50px;
		background-color: #01bbd4;
		border: none;
		cursor: pointer;

		&.disabled {
			cursor: default;
			background-color: rgba(0, 0, 0, 0.2);
			color: rgba(255, 255, 255, 0.3);
		}
	}



}
</style>
