<template lang="html">
<section class="redeem">
	<div class="header">Redeem bundle</div>

	<loader v-if="loading" name="bundle item"></loader>
	<div class="item" v-else>
		<div class="credits">
			<div class="count">{{ selected.item.credits }}</div>
			<div class="remaining">{{ selected.item.credits === 1 ? "Credit" : "Credits" }}<br>Left</div>
		</div>

		<div class="meta">
			<div class="name">In your <b>{{ selected.item.bundle_name }}</b> bundle</div>
			<div class="desc" v-if="selected.item.softener_upgrade">Free Softener Upgrade</div>

			<div class="info">
				<div class="name">{{ selected.item.name_string }}</div>
				<div class="desc">{{ selected.item.treatment_string }} </div>
				<div class="desc">{{ selected.item.whites ? "Whites" : "Colours" }} {{ selected.item.feathers ? "Feather Duvet" : "" }}</div>
			</div>
		</div>
	</div>

	<content-view v-model="selected.whites"></content-view>
</section>
</template>

<script>
import ContentView from "./Content";

export default {
  props: ["value"],

  components: {
    ContentView,
  },

  data() {
    return {
      loading: true, // Whether loading data
      selected: this.value,
    };
  },

  created() {
    let id = this.selected.item.id;
    this.$api.get("/bundle/item/" + id).then((res) => {
      this.loading = false;
      this.selected.item = res.data;
    });
  },
};
</script>

<style lang="less" scoped>
@mobile: ~"(max-width: 1100px)";
section.redeem {
  .item {
    display: flex;
    margin-top: 20px;
    cursor: default;
  }

  .credits {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media @mobile {
      border: none;
    }
    border: solid 1px #ddd;
    border-radius: 100px;
    height: 100px;
    width: 100px;

    .count {
      font-size: 24px;
    }

    .remaining {
      text-transform: uppercase;
      font-size: 12px;
    }
  }

  .meta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 14px;
  }

  .name {
    font-size: 18px;
  }

  .desc {
    margin-top: 5px;
    color: #888;
  }

  .info {
    padding: 10px;
    margin-top: 10px;
    border: solid 1px #ddd;
    border-radius: 4px;
  }
}
</style>
