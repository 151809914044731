<template lang="html">
<section class="content">
	<div class="header">Choose the content of your laundry load</div>
	<div class="options">
		<div class="option" :class="{ selected: !whites }" @click="setWhites(false)">
			<img class="image" :src="img.colours">
			<div class="name">Colours</div>
		</div>

		<div class="option" :class="{ selected: whites }" @click="setWhites(true)">
			<img class="image" :src="img.whites">
			<div class="name">Whites</div>
		</div>
	</div>
</section>
</template>

<script>
import colours from "../../assets/icons/colours.png";
import whites from "../../assets/icons/whites.png";

export default {
	props: [
		"value"
	],

	data() {
		return {
			img: { colours, whites },
			whites: this.value
		}
	},

	methods: {
		setWhites(whites) {
			this.whites = whites;
			this.$emit("input", this.whites);
		},
	}
}
</script>
