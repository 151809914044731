<template lang="html">
<section class="bundles">
	<div class="header">Bundles</div>

	<loader name="your bundles" v-if="loading"></loader>

	<div class="bundles" v-if="!loading && bundles.length">
		<div class="bundle"   v-for="bundle in bundles">
			<div   class="meta">
				<div class="name">{{ bundle.name }}</div>
				<div v-if="!bundle.isexpired" class="expires">{{ bundle.renew ? "Renews" : "Expires" }} {{ bundle.time_string }} ({{ bundle.date_string }})</div>
				<div v-if="bundle.isexpired" class="expires">{{ bundle.renew ? "Renews" : "Expires" }} in 15 minutes</div>
				<div class="renew button" v-if="bundle.renew" @click="cancel(bundle)">Cancel</div>
				<div class="renew button" v-if="!bundle.renew" @click="renew(bundle)">Enable Auto-Renew</div>
				<div class="cost">{{ bundle.cost | formatPrice }}<span style=""> inc. VAT</span></div>
			</div>

			<div class="items">
				<div class="item" :class="{ enabled: item.credits > 0 }" v-for="item in bundle.items" @click="redeem(item)">
					<div class="credits">{{ item.credits }}</div>
					<div class="name">{{ item.name_string }} ({{ item.treatment_string }}) {{ item.whites ? "Whites" : "Colours" }} {{ item.feathers ? "Feather Duvet" : "" }} {{ bundle.softener_upgrade ? "(luxury softener)" : null }}</div>
					<div class="redeem button" v-if="item.credits > 0">Redeem</div>
				</div>
				
			</div>

			<div class="id">BUNDLE <b>#{{ bundle.id }}</b></div>
		</div>
	</div>

	<div class="new" v-if="!loading && !bundles.length">
		<img class="image" :src="img.bundles">
		<div class="title">You don't have any bundles</div>
		<div class="subtitle">Using us a lot? Why not save yourself some money and grab yourself a bundle subscription</div>
		<div class="button blue" @click="newBundle">New bundle</div>
	</div>
</section>
</template>

<script>
import bundles from "../../assets/icons/bundles.png";

export default {
	data() {
		return {
			img: { bundles },

			loading: true, // Loading data from api
			bundles: []
		}
	},

	created() {
		this.reload();
	},

	methods: {
		newBundle() {
			this.$router.push({
				path: "/order",
				query: { category: 2 }
			});
		},

		reload() {
			this.$api.get("/user/bundles").then(res => {
				this.loading = false;
				this.bundles = res.data;
			});
		},

		redeem(item) {
			if (item.credits) {
				this.$router.push({
					path: "/order",
					query: { redeem: item.id }
				});
			}
		},

		cancel(bundle) {
			let confirm = window.confirm("Are you sure you wish to cancel this bundle?");

			if (confirm) {
				this.$api.get("/bundle/" + bundle.id + "/cancel").then(res => {
					this.reload();
				});
			}
		},
		    renew(bundle) {
			let confirm = window.confirm('Are you sure you wish to turn on auto renew for this bundle?')

			if (confirm) {
				this.$api.get('/bundle/' + bundle.id + '/renew').then(res => {
					if (!res.data)
						window.alert(`Bundle Outdated. Please buy a new bundle`);
					this.reload()
				})
			}
		}
	},

	filters: {
		formatPrice: price => "£" + price.toFixed(2),
		VatPrice: price => "£" + (price * 0.20).toFixed(2),
	}
}
</script>

<style lang="less" scoped>
@mobile: ~'(max-width: 1100px)';
section.bundles {
	.bundle {
		border: solid 1px rgba(1, 186, 212, 0.3);
		background-color: rgba(1, 186, 212, 0.03);
		border-radius: 4px;
		margin: 10px 0;

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		.button {
			cursor: pointer;
			text-transform: uppercase;
			font-size: 14px;
			padding: 5px 12px;
			@media @mobile {margin-top:20px;}
		}

		.meta {
			display: flex;
			align-items: center;
			padding: 20px;
			padding-bottom: 5px;

				@media @mobile {display: block;
margin: auto;text-align:center; }

			.name {
				font-size: 22px;
				color: #01BAD4;
				flex-grow: 1;
				@media @mobile {padding-top:20px;}	
			}

			.softener {
				cursor: default;
				margin-right: 10px;
				font-size: 14px;
				color: #01BAD4;
				border: solid 1px #01BAD4;
				padding: 4px 10px;
				border-radius: 4px;
			}

			.expires {
				margin-right: 10px;
				@media @mobile {padding-top:20px;}
			}
			
			.renew {
				margin-right: 10px;
				border-radius: 100px;
				border: solid 1px rgba(1, 186, 212, 0.5);
				color: #01BAD4;

				&:hover { background-color: rgba(1, 186, 212, 0.1); }
				&:active { background-color: rgba(1, 186, 212, 0.2); }
			}

			.cost {
				color: #01BAD4;
				@media @mobile {padding-top:20px;}
			}
		}
.redeem {
		@media @mobile {display:none;}

			}
		.items {
			padding: 0 20px;

			.item {
				display: flex;
				align-items: center;
				border-radius: 100px;
				padding: 5px;
				margin: 10px 0;
				color: rgba(1, 186, 212, 0.5);
				cursor: not-allowed;

				.credits {
					display: flex;
					align-items: center;
					justify-content: center;

					border-radius: 24px;
					border: solid 1px rgba(1, 186, 212, 0.5);
					color: #01BAD4;

					width: 28px;
					height: 28px;
					@media @mobile {width:45px;}
				}

				.name {
					flex-grow: 1;
					margin-left: 10px;
					@media @mobile {font-size:14px!important;}	
				}

				&.enabled {
					cursor: pointer;
					background-color: rgba(255, 255, 255, 0.5);
					border: solid 1px rgba(1, 186, 212, 0.3);
					color: #151515;

					&:hover {
						color: #01BAD4;

						.credits {
							background-color: #01BAD4;
							color: #FFF;
						}
					}
				}
			}
		}

		.id {
			text-align: right;
			margin-top: 10px;
			font-size: 12px;
			color: rgba(1, 186, 212, 0.5);

			padding-right: 20px;
			padding-bottom: 10px;
		}
	}
}
</style>
