<template lang="html">
<section class="standalone">	

<type-view v-model="selected.type"></type-view>

<div  class="header">Choose desired load weight</div>
	<loader v-if="loading" name="laundry load types"></loader>
	<div class="options" v-else>
		<div class="option" v-if="selected.type === 'clothes'|| !selected.type"  :class="{ selected: load.type === (selected.load ? selected.load.type : null) }" v-for="load in clothesLoads" @click="setLoad(load)">
			<div class="name">{{ load.name_string  }}</div>
			<div class="desc">{{ load.treatment_string }}</div>
			<div class="price">{{ load.price | formatPrice }}</div>
		</div>
		<div class="option" v-if="selected.type === 'towels'" :class="{ selected: load.type === (selected.load ? selected.load.type : null) }" v-for="load in towelLoads" @click="setLoad(load)">
			<div class="name">{{ load.name_string  }}</div>
			<div class="desc">{{ load.treatment_string }}</div>
			<div class="price">{{ load.price | formatPrice }}</div>
		</div>
		<div class="option" v-if="selected.type === 'duvets'" :class="{ selected: load.type === (selected.load ? selected.load.type : null) }" v-for="load in duvetsLoads	" @click="setLoad(load)">
			<div class="name">{{ load.name_string }}</div>
			<div class="desc">{{ load.treatment_string }}</div>
			<div class="price">{{ load.price | formatPrice }}</div>
		</div>
			<div class="option" v-if="selected.type === 'mixed'" :class="{ selected: load.type === (selected.load ? selected.load.type : null) }" v-for="load in mixed" @click="setLoad(load)">
			<div class="name">{{ load.name_string }}</div>
			<div class="desc">{{ load.treatment_string }}</div>
			<div class="price">{{ load.price | formatPrice }}</div>
		</div>

	</div>
	<feather-view v-if="selected.type === 'duvets'" v-model="selected.feather"></feather-view>
	<content-view v-model="selected.whites"></content-view>

	<div v-if="showSoftener" class="header">Choose a fabric softener for your laundry</div>
	<div v-if="showSoftener" class="options">
		<div class="option" v-for="softener in softeners" :class="{ selected: selected.softener.type === softener.type }" @click="setSoftener(softener)">
			<div class="name">{{ softener.name }}</div>
			<div class="price">{{ softener.price | formatPrice }}</div>
		</div>
	</div>

	<preferences-view :hide-drying="selected.type === 'mixed'"></preferences-view>
</section>
</template>

<script>
import ContentView from "./Content";
import TypeView from "./Type";
import FeatherView from "./Feather";
import PreferencesView from "./Preferences"

export default {
	components: {
		ContentView,
		TypeView,
		FeatherView,
		PreferencesView,
		
	},

	props: [
		"value"
	],

	data() {
		return {
			loading: true, // Whether loading AJAX data
			loads: [], // Array of standalone loads
			clothesLoads: [],
			duvetsLoads: [],
			towelLoads: [],
			mixed:[],
			showSoftener: false,
			softeners: [
				{ type: 0, name: "No softener", price: 0.00 },
				{ type: 1, name: "Standard softener", price: 0.50 },
				{ type: 2, name: "Luxury softener", price: 1.00 },
			],
			
			selected: this.value,

			showCalculator: false
		}
	},

	created() {
			this.selected.softener = { type: 0, name: "No softener", price: 0.00 },
		//this.selected.type ="clothes"
		this.$api.get("/products/standalone").then(res => {
			this.loading = false;
		
			let filteredloads = res.data
			
			for( var i = filteredloads.length-1; i--;){
if ( filteredloads[i].kg >= 18) filteredloads.splice(i, 1);

}
			this.loads = filteredloads; 

			this.clothesLoads =  this.loads.filter(function(load) {
		    return load.clothes
			});
			this.towelLoads =  this.loads.filter(function(load) {
		    return load.towels
			});
			this.duvetsLoads =  this.loads.filter(function(load) {
		    return load.duvettab
			});
			this.mixed = this.loads.filter(function (load) {
					return load.mixed
				});		

		});
	},

	methods: {
		setLoad(load) {
			this.selected.load = load;
			this.$emit("input", this.selected);
		this.updatePreferencesView();
		},

		setSoftener(softener) {
			this.selected.softener = softener;
			this.$emit("input", this.selected);
		}
	},
 updatePreferencesView() {
      this.$forceUpdate(); // This forces the view to update
    },
	filters: {
		formatPrice: price => "£" + price.toFixed(2)
	}
}
</script>
