<template>
  <section class="ownbag">
    <div class="header">Own bag discount </div>

    <div
      class="ownbag" style="display:flex;"
      :class="{ active: checked }"
      @click="checked = !checked"
    >
      <input type="checkbox" v-model="checked" />
      <span>We recommend that customers supply laundry in their own kitbag or ikea bag in order to reduce our plastic waste and to receive <b>20p</b> discount</span>
    </div>

  </section>
</template>

<script>
export default {
  props: ["value"],

  methods: {
    toggle() {
      this.$emit("input", !this.value);
    }
  },

  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="less" scoped>
section.ownbag {
  font-size: 14px;

  li:not(:last-of-type) {
    margin-bottom: 12px;
  }

  .ownbag {
    cursor: default;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.025);
    border-radius: 4px;

    transition: background-color 0.3s, color 0.3s;

    &.active {
      background-color: rgba(46, 204, 113, 0.1);
      color: rgba(39, 174, 96, 1);
    }

    span {
      margin-left: 10px;
      display: inline-block;
    width: 90%;
    }
   
  }
}
</style>
