<template>
  <section class="thanks">
    <div class="header">Your Washcycle order is now confirmed</div>
  <h1>By supporting Wash Cycle you are supporting your environment!<br> </h1><h2>Congratulations, you just funded 1 trees to be planted through our partnership with Ecologi.com! 🌳🌳🌳</h2>
 
<div style="padding:30px; text-align:center;">
 <a href="https://ecologi.com/washcycleltd" target="_blank" rel="noopener noreferrer" title="View our Ecologi profile" style="width:200px;">
  <img alt="We offset our carbon footprint via Ecologi" src="https://api.ecologi.com/badges/cpw/5e8de364ff618e001660f1a5?black=true&landscape=true" style="width:200px;" />
</a>
</div>

      <div @click="back" class="backbutton TrackThankYou"> Go to dashboard</div>	
  </section>
</template>

<script>
const DEV = process.env.NODE_ENV !== "production";
export default {

 data() {
		return {
     
       impact: {
         trees:null,
         carbonOffset:null,
         },
		}
	},
created(){

this.$api.get("/user/buyimpact").then(res => {


})


        
},
  methods: {
    back(){
      	if (!DEV){
			 window.dataLayer = window.dataLayer || [];
 			 window.dataLayer.push({
 			'event': 'Order Processed'
 			});}
      this.$router.push("/")
    }

  }
};
</script>

<style lang="less" scoped>
@mobile: ~'(max-width: 1100px)';
section.thanks {
  .backbutton{
			border: solid 1px #01BAD4;
			border-radius: 4px;
			padding: 14px;
			margin: 5px;
			color:#01BAD4;
			
			text-align: center;
			cursor: pointer;
			font-size: 18px;
			    max-width: 1070px;
	margin: auto;
	margin-left: 10px;
  }
  .agree{	@media @mobile {}}
  .agree-txt{
@media @mobile {padding-top: 20px;}

  }
  font-size: 14px;

  li:not(:last-of-type) {
    margin-bottom: 12px;
  }


  .checkbox {
    cursor: default;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.025);
    border-radius: 4px;
display: flex;
    transition: background-color 0.3s, color 0.3s;

    &.active {
      background-color: rgba(46, 204, 113, 0.1);
      color: rgba(39, 174, 96, 1);
    }

			
			&.disabled {
				opacity: 0.5;
				color: #888;
				cursor: default;
			}
			}
    span {
      margin-left: 10px;
    }
  }

</style>
