<template lang="html">
<section class="loader">
	<div class="spinner"></div>
	<div class="text" v-if="!simple">
		Loading<span class="name" v-if="name"> {{ name }}</span>...
	</div>
</section>
</template>

<script>
export default {
	props: [
		"name",
		"simple"
	]
}
</script>

<style lang="less">
section.loader {
	display: flex;
	align-items: center;
	padding: 20px 10px;
	color: #436266;

	@keyframes spin {
		from { transform: rotate(0deg); }
		to { transform: rotate(360deg); }
	}

	.spinner {
		width: 16px;
		height: 16px;
		border: solid 2px #01BAD4;
		border-right: solid 3px transparent;
		border-left: solid 3px transparent;
		border-radius: 50%;
		animation: 1s spin linear infinite;
	}

	.text {
		margin-left: 10px;
	}

	.name {
		color: #01BAD4;
		font-weight: 500;
	}
}
</style>
