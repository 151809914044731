import { render, staticRenderFns } from "./Slots.vue?vue&type=template&id=70322961&scoped=true&lang=html&"
import script from "./Slots.vue?vue&type=script&lang=js&"
export * from "./Slots.vue?vue&type=script&lang=js&"
import style0 from "./Slots.vue?vue&type=style&index=0&id=70322961&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70322961",
  null
  
)

export default component.exports