<template>
  <section class="terms">
    <div class="header">Terms and conditions</div>

    <div
      class="checkbox"
      :class="{ active: checked }"
      @click="checked = !checked"
    >
      <input style="display: inline-flex;" type="checkbox" v-model="checked" />
      <span class="agree">I agree to the terms and conditions outlined below</span>
    </div>
<div class="agree-txt">
    <p>By ticking the box above, I agree that:</p>
    <ol>
    <li>
        WashCycle will not be responsible for checking the care label on any
        items exchanged for servicing.
    </li>
    <li>
        It is my own responsibility (as the customer) to ensure the items of
        laundry handed to Wash Cycle are machine washable and tumble-dryer safe.
    </li>
    <li>Please bare in mind that bulky items may incur an additional charge as they will require additional washing machine and tumble dryer space.</li>
    <li>If your laundry exceeds the booked in weight, your card shall be charged at a rate of £1.22/Kg inc vat.</li>
    <li>
        WashCycle will charge a fee of £4.20 inc vat. if a member of our team arrives for
        collection or re-delivery after a time has already been agreed upon and
        there is no-one available to respond and collect their belongings.
    </li>
    <li>
        Please note that, any person who is found to be responsible for the spoilage of clothes as a result of mixing in colours with a white wash, or vice versa, shall be held responsible and thus liable for the damages themselves.
    </li>  
    <li>
      WashCycle will charge a fee of £2.40 inc vat. if your laundry includes children's clothes (under 10 years old)
    </li>
    <li>
        To see full terms and conditions, please click <a href="https://washcycle.co.uk/terms" style="display:inline-block; text-decoration-line: blink;">here</a>.
    </li>  
    </ol>
    </div>
  </section>
</template>

<script>
export default {
  props: ["value"],

  methods: {
    toggle() {
      this.$emit("input", !this.value);
    }
  },

  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@mobile: ~'(max-width: 1100px)';

section.terms {
  .agree {
    @media @mobile {}
  }

  .agree-txt {
    @media @mobile {
      padding-top: 20px;
    }

  }

  font-size: 14px;

  li:not(:last-of-type) {
    margin-bottom: 12px;
  }

  .checkbox {
    cursor: default;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.025);
    border-radius: 4px;
    display: flex;
    transition: background-color 0.3s, color 0.3s;

    &.active {
      background-color: rgba(46, 204, 113, 0.1);
      color: rgba(39, 174, 96, 1);
    }

    span {
      margin-left: 10px;
    }
  }
}
</style>
