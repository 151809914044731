<template lang="html">

<section class="profile">
<loader v-if="loading" :simple="true"></loader>
<div class="wrapper-full" v-else>

	<h1>Hi {{user.name}}</h1>

<h3  v-if="treecount" style="padding:20px">Congratulations, you planted {{treecount}} trees since we started our partnership with Ecologi.com 🌳🌳🌳</h3>
<div class="pill" :class="{ selected: category === 1 }"  @click="setcategory(1)"><svg style="top: 5px;
position: relative;width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7.07,18.28C7.5,17.38 10.12,16.5 12,16.5C13.88,16.5 16.5,17.38 16.93,18.28C15.57,19.36 13.86,20 12,20C10.14,20 8.43,19.36 7.07,18.28M18.36,16.83C16.93,15.09 13.46,14.5 12,14.5C10.54,14.5 7.07,15.09 5.64,16.83C4.62,15.5 4,13.82 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,13.82 19.38,15.5 18.36,16.83M12,6C10.06,6 8.5,7.56 8.5,9.5C8.5,11.44 10.06,13 12,13C13.94,13 15.5,11.44 15.5,9.5C15.5,7.56 13.94,6 12,6M12,11A1.5,1.5 0 0,1 10.5,9.5A1.5,1.5 0 0,1 12,8A1.5,1.5 0 0,1 13.5,9.5A1.5,1.5 0 0,1 12,11Z" />
</svg> User Details</div>
<div class="pill" :class="{ selected: category === 4 }"  @click="setcategory(4)"><svg style="top: 5px;
position: relative;width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M14.83,11.17C16.39,12.73 16.39,15.27 14.83,16.83C13.27,18.39 10.73,18.39 9.17,16.83L14.83,11.17M6,2H18A2,2 0 0,1 20,4V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2M7,4A1,1 0 0,0 6,5A1,1 0 0,0 7,6A1,1 0 0,0 8,5A1,1 0 0,0 7,4M10,4A1,1 0 0,0 9,5A1,1 0 0,0 10,6A1,1 0 0,0 11,5A1,1 0 0,0 10,4M12,8A6,6 0 0,0 6,14A6,6 0 0,0 12,20A6,6 0 0,0 18,14A6,6 0 0,0 12,8Z" />
</svg> Wash Preferences</div>
<div class="pill" :class="{ selected: category === 2 }"  @click="setcategory(2)"><svg style="top: 5px;
position: relative;width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M20,8H4V6H20M20,18H4V12H20M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" />
</svg> Billing</div>
<div class="pill" :class="{ selected: category === 3 }"  @click="setcategory(3)"> <svg style="top: 5px;
position: relative;width:24px;height:24px" viewBox="0 0 24 24">
       <path fill="currentColor" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
</svg> Addresses</div>
<div class="pill" :class="{ selected: category === 5 }"  @click="setcategory(5)"> 
	<svg class="icon" :class="{ iconSelected: category === 5 }" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M88.2 309.1c9.8-18.3 6.8-40.8-7.5-55.8C59.4 230.9 48 204 48 176c0-63.5 63.8-128 160-128s160 64.5 160 128s-63.8 128-160 128c-13.1 0-25.8-1.3-37.8-3.6c-10.4-2-21.2-.6-30.7 4.2c-4.1 2.1-8.3 4.1-12.6 6c-16 7.2-32.9 13.5-49.9 18c2.8-4.6 5.4-9.1 7.9-13.6c1.1-1.9 2.2-3.9 3.2-5.9zM0 176c0 41.8 17.2 80.1 45.9 110.3c-.9 1.7-1.9 3.5-2.8 5.1c-10.3 18.4-22.3 36.5-36.6 52.1c-6.6 7-8.3 17.2-4.6 25.9C5.8 378.3 14.4 384 24 384c43 0 86.5-13.3 122.7-29.7c4.8-2.2 9.6-4.5 14.2-6.8c15.1 3 30.9 4.5 47.1 4.5c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176zM432 480c16.2 0 31.9-1.6 47.1-4.5c4.6 2.3 9.4 4.6 14.2 6.8C529.5 498.7 573 512 616 512c9.6 0 18.2-5.7 22-14.5c3.8-8.8 2-19-4.6-25.9c-14.2-15.6-26.2-33.7-36.6-52.1c-.9-1.7-1.9-3.4-2.8-5.1C622.8 384.1 640 345.8 640 304c0-94.4-87.9-171.5-198.2-175.8c4.1 15.2 6.2 31.2 6.2 47.8l0 .6c87.2 6.7 144 67.5 144 127.4c0 28-11.4 54.9-32.7 77.2c-14.3 15-17.3 37.6-7.5 55.8c1.1 2 2.2 4 3.2 5.9c2.5 4.5 5.2 9 7.9 13.6c-17-4.5-33.9-10.7-49.9-18c-4.3-1.9-8.5-3.9-12.6-6c-9.5-4.8-20.3-6.2-30.7-4.2c-12.1 2.4-24.7 3.6-37.8 3.6c-61.7 0-110-26.5-136.8-62.3c-16 5.4-32.8 9.4-50 11.8C279 439.8 350 480 432 480z"/></svg> Referrals</div>
	<div class="pill" :class="{ selected: category === 6 }"  @click="setcategory(6)"> 
	<svg style="top: 5px; position: relative;width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M20 6h-2.18c.11-.31.18-.65.18-1 0-1.66-1.34-3-3-3-1.05 0-1.96.54-2.5 1.35l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2m-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1M9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1m11 15H4v-2h16zm0-5H4V8h5.08L7 10.83 8.62 12 11 8.76l1-1.36 1 1.36L15.38 12 17 10.83 14.92 8H20z"/></svg> Gift Codes</div>
<user-view v-if="category === 1" ></user-view>
<billing-view v-if="category === 2" ></billing-view>
<addresses-view v-if="category === 3"></addresses-view>
<preferences-view v-if="category === 4"></preferences-view>
<refferals-view v-if="category === 5"></refferals-view>
<gift-view v-if="category === 6"></gift-view>
</div>
</section>
</template>

<script>
import Vuetify from 'vuetify';
import BillingView from "./Billing";
import UserView from "./User";
import PreferencesView from "./Preferences";
import AddressesView from "./Addresses";
import RefferalsView from "./Refferals";
import GiftView from "./Gift";
export default {
	components: {
		BillingView,
		AddressesView,
		PreferencesView,
		UserView,
		RefferalsView,
		GiftView,
	},

	data() {
		return {
			loading: true,
			processing: false,
			tab:null,
			category:1,
			treecount:null,
		
		
	user: {
				name: null,
				email: null
			},
			showNewCard: false
		}
	},

	created() {
		this.$api.get("/user").then(res => {
			this.user = res.data;
			this.loading = false;
		});
			this.$api.get("/user/ecologycount").then(res => {
		
			this.treecount = res.data;
			
		
		});
	},

	methods: {
		reload() {
			
		},
		
	
	setcategory(i){
	this.category = i;
	
	},
		close() {
			
		},

	
		
	}
}
</script>

<style lang="less" scoped>
@mobile: ~'(max-width: 1100px)';
section.profile {
.pill{padding: 8px 18px;
margin-right: 20px;
color:
#01bad4;
text-transform: uppercase;
font-size: 14px;
border: solid 1px #01bad4;
width: 27%;
display: inline-block;
margin: 10px;
border-radius: 100px;
cursor: pointer;
text-align:center;
@media @mobile {
display: block;
text-align:center;
width: 100%;

}

}
.icon{fill: #01bad4;width: 24px;height: 24px;top: 5px;position: relative;}
.iconSelected{fill: white;width: 24px;height: 24px;top: 5px;position: relative;}
.selected{background: #01bad4;
color:white;}
}
</style>
