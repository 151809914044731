<template lang="html">
<section class="addresses"><div class="wrapper-full">
	<h1>Addresses</h1>

	<loader v-if="loading" name="your addresses"></loader>
	<div class="addresses" v-else>
		<div class="address new" @click="showNewAddress = true">Add a new address</div>

		<div class="address" v-for="address in addresses">
			<div class="meta">
				<div class="name">{{ address.name }}</div>
				<div class="lines">{{ address.string }}</div>
				<div class="phone" v-if="address.phone">{{ address.phone }}</div>
			</div>
			<div class="actions">
				<div class="action" @click="remove(address)">Delete</div>
				<!-- <div class="action">Edit</div> -->
			</div>
		</div>
	</div>

	<new-address v-if="showNewAddress" @close="close"></new-address>
</div></section>
</template>

<script>
import NewAddress from "../components/NewAddress";

export default {
	components: {
		NewAddress
	},

	data() {
		return {
			addresses: [],
			preferred: null,
			loading: true, // Whether loading AJAX data

			showNewAddress: false
		}
	},

	created() {
		this.reload();
	},

	methods: {
		remove(address) {
			let confirm = window.confirm("Are you sure you wish to delete this address?");

			if (confirm) {
				this.$api.delete("/address/" + address.id).then(this.reload);
			}
		},

		reload() {
			this.$api.get("/user/addresses").then(res => {
				let { addresses, preferred } = res.data;

				this.loading = false;
				this.addresses = addresses;
				this.preferred = preferred;
			});
		},

		close() {
			this.showNewAddress = false;
			this.reload();
		}
	}
}
</script>

<style lang="less" scoped>
@mobile: ~'(max-width: 1100px)';
section.addresses {
	.addresses {
		display: flex;
		flex-wrap: wrap;

		.address {
			border: solid 1px #DDD;
			border-radius: 4px;
			margin: 10px;
			overflow: hidden;
			@media @mobile {
width: 100%;}
			.meta {
				padding: 20px;

				.name { font-weight: 500; }
				.lines { margin-top: 4px; }
				.phone { margin-top: 4px; color: #777; }
			}

			.actions {
				display: flex;
				padding: 10px;
				border-top: solid 1px #DDD;
				background-color: #FAFAFA;

				.action {
					padding: 10px;
					cursor: pointer;
					color: #01BAD4;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			&.new {
				padding: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #888;
				border: dashed 1px #888;
				cursor: pointer;
	@media @mobile {
width: 100%;}
				&:hover {
					color: #01BAD4;
				}
			}
		}
	}
}
</style>
