// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.loader {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  color: #436266;
}
@keyframes spin {
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(360deg);
}
}
section.loader .spinner {
  width: 16px;
  height: 16px;
  border: solid 2px #01BAD4;
  border-right: solid 3px transparent;
  border-left: solid 3px transparent;
  border-radius: 50%;
  animation: 1s spin linear infinite;
}
section.loader .text {
  margin-left: 10px;
}
section.loader .name {
  color: #01BAD4;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./client/components/Loader.vue","webpack://./Loader.vue"],"names":[],"mappings":"AACA;EACC,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;ACAD;ADEC;AACC;IAAO,uBAAA;ACCP;ADAA;IAAK,yBAAA;ACGL;AACF;ADZA;EAYE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mCAAA;EACA,kCAAA;EACA,kBAAA;EACA,kCAAA;ACGF;ADrBA;EAsBE,iBAAA;ACEF;ADxBA;EA0BE,cAAA;EACA,gBAAA;ACCF","sourcesContent":["\nsection.loader {\n\tdisplay: flex;\n\talign-items: center;\n\tpadding: 20px 10px;\n\tcolor: #436266;\n\n\t@keyframes spin {\n\t\tfrom { transform: rotate(0deg); }\n\t\tto { transform: rotate(360deg); }\n\t}\n\n\t.spinner {\n\t\twidth: 16px;\n\t\theight: 16px;\n\t\tborder: solid 2px #01BAD4;\n\t\tborder-right: solid 3px transparent;\n\t\tborder-left: solid 3px transparent;\n\t\tborder-radius: 50%;\n\t\tanimation: 1s spin linear infinite;\n\t}\n\n\t.text {\n\t\tmargin-left: 10px;\n\t}\n\n\t.name {\n\t\tcolor: #01BAD4;\n\t\tfont-weight: 500;\n\t}\n}\n","section.loader {\n  display: flex;\n  align-items: center;\n  padding: 20px 10px;\n  color: #436266;\n}\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\nsection.loader .spinner {\n  width: 16px;\n  height: 16px;\n  border: solid 2px #01BAD4;\n  border-right: solid 3px transparent;\n  border-left: solid 3px transparent;\n  border-radius: 50%;\n  animation: 1s spin linear infinite;\n}\nsection.loader .text {\n  margin-left: 10px;\n}\nsection.loader .name {\n  color: #01BAD4;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
