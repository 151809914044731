<template lang="html">
<section class="pass-dialog dialog-wrapper" >
	<div @click="close" class="bg"></div>
	<div class="dialog">
	
		<label class="header-primary">Change Password </label>
			
		
<div style="padding: 10px;"><span style="padding-right: 55px;">Old Password: </span><input type="password" v-model="passwords.old"  placeholder="" class="details-input" ></div>
<div style="padding: 10px;"><span style="padding-right: 50px;">New Password: </span><input type="password" minlength="8"  v-model="passwords.new" placeholder="" class="details-input" ></div>
<div style="padding: 10px;"><span style="padding-right: 20px;">Confirm Password: </span><input type="password" minlength="8"  v-model="passwords.confirm" placeholder="" class="details-input" ></div>
<span class="forgot-tip">You can also log out and use forgot password button durring login to reset your password</span>
	
	

<div class="pill selected button" @click="changepass">Change Password</div>





	</div>

	<div class="background" @click="close"></div>
</section>
</template>

<script>
import Vue from "vue";
export default {
	
	props: [
		"show",
		"user",
	
		
	],
	filters: {
		formatDate: date => date.substring(0,10)
	},
	data() {
		return {
		selection:null,		
		chargeinput:"",
		passwords: {
				userid:null,
				old: null,
				new: null,
				confirm:null,
				
			},
		showWarn:false,
		BundleData: [],
		UserData: [],
		bundleItems: [],
		charge:null
		}
	},

	methods: {
	
	 changepass() {
		 let confirm = window.confirm("Are you sure you want to change password ");
		 if (confirm){	

			 if (this.passwords.new !== this.passwords.confirm ){

				 alert(" New password and confirm password does not match ")
				
			 }
			else if (this.passwords.new == this.passwords.old ){

				 alert("New password and old password is the same ")
				  
			 }
			 else if (!this.passwords.old ){

				 alert("Please enter old password")
				  
			 }
			  else if (!this.passwords.new ){

				 alert("Please enter new password")
				  
			 }
			   else if (!this.passwords.confirm ){

				 alert("Please confirm new password")
				  
			 }

			else{

			 	this.passwords.userid = this.user.id;
				let passwords = this.passwords;
               Vue.prototype.$api.post("/user/updatepassword", passwords ).then(res => {
				    if(res.status == '200'){
					   	window.alert("Password Changed Successfully")
						//this.$emit("close");	
				   }
				  else if(res.status == '401'){
					   	window.alert("Current Password Does Not Match")
						//this.$emit("close");	
				   }
				   else { 	window.alert("Something went wrong")}
				});}
}
				},
      
		

		close() {
			this.$emit("close");
		}
	},
	watch: {

		show() {
			let vm = this;

			if (this.show == true){
	
	

		
	
	
			}
		}
	},
	computed: {
  isDisabled() {
	  
    // evaluate whatever you need to determine disabled here...
   if ( this.selection !== null && this.chargeinput !== "" ){
	    this.showWarn = true;
	   return true;
	  
   }
   else{
	    this.showWarn = false;
   }
    if ( this.selection == null && this.chargeinput == "" ){
	   return true;
   }
	
   


  }
}

}
</script>

<style lang="less" scoped>
@mobile: ~'(max-width: 1100px)';

.pill{padding: 8px 18px;
margin-right: 20px;
color:#01bad4;
text-transform: uppercase;
font-size: 14px;
border: solid 1px #01bad4;

display: inline-block;
margin: 10px;
border-radius: 100px;
cursor: pointer;
text-align:center;}

.details-input{padding: 10px;
margin-top:10px;
color:#01bad4;
font-size: 14px;
border: solid 1px #01bad4;
border-radius: 7px;}
.bg{background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    top: 0;
	left: 0;}
	.forgot-tip{display: block;
    font-size: 13px;
    padding-bottom: 20px;
    padding-top: 10px;
    color: gray;}
.selected {
   background: #01bad4;
    color: white;
    height: 40px;
    text-align: center;
}

	.dialog {
       position: absolute;
	top: 50%;
	border-radius: 40px;
    left: 0%;
    background: white;
    padding: 20px;
    width: 100%;
    text-align: center;
	z-index: 100;
	
	}
	

</style>
