<template lang="html">
<section class="gift">


<div v-if="!giftCode" class="">
	<h1>Buy Gift Codes</h1>
 <div>
	<p class="text">Here, you can purchase gift code vouchers for your friends and family.</p>
</div>
<div style="padding: 10px;">
	
    <div style="display:flex; align-items: center;">
        <span style="padding-right: 5px;padding-top: 10px;">Amount:</span>
				<span class="input-pound left">
            <input v-model.number="gift.price" :placeholder="gift.price" class="details-input" id="referralCodeInput" type="number" min="12" max="100">

</span>
    </div>
    <span style="display:block;padding-right: 50px;padding-top:10px;">Pick Card:</span>

			<loader name="your cards"  v-if="processing"></loader>
	<div class="options" v-if="!processing">
		<div class="option" :class="{ selected: selected.id === card.id }" v-for="card in cards" @click="setCard(card)">
			<div class="brand">{{ card.brand }}</div>
			<div class="number">•••• •••• •••• {{ card.last4 }}</div>
			<div class="expires">{{ card.expiry_string }}</div>
		</div>

	</div>
    <span class="pill selected button disabled" v-if="processing" @click="trypay()">Buy</span>

    <span class="pill selected button" v-else @click="trypay()">Buy</span>
</div>


</div>	
<div  v-if="giftCode" class="">
<h1 class="margin-bottom: 0px;">Here is your gift code:</h1>
<span class="font-style: italic;font-size:18px">(This was also emailed to you)</span>
<h1 style="font-weight:500;color:#01bad4;">{{this.giftCode}}</h1>

</div>
</section>
</template>

<script>
import {
	loadStripe
} from '@stripe/stripe-js';
import Cookies from 'js-cookie';

export default {

	data() {
		return {
			stripe: loadStripe(STRIPE_KEY),
			cards: [],
			giftCode:null,
			selected: {id:null},
			processing: false,
			gift: {
				price: 12,
				appVersion: 30,
			},
			user: {
				name: null,
				email: null,
				id: null
			},
		}
	},

	created() {
		this.$api.get("/user").then(res => {
		this.user = res.data;
		});
			this.processing = true;

			this.$api.get("/user/cards").then(res => {
			this.processing = false;
			this.cards = res.data.cards;
		});
	},

	methods: {

		setCard(card) {
			this.selected = card;
		},

		trypay() {
			let vm = this;
			if (Cookies.get('washcycle-gift')) {
				alert("Last order is still processing, please try again in 5 minutes")
				return;
			}
			var inTwentySec = new Date(new Date().getTime() + 0.50 * 6 * 1);
			Cookies.set('washcycle-gift', 'processing', {
				expires: inTwentySec,
			});
				const giftCodeData = { ...this.gift, id:this.selected.id };

				this.processing = true;
				this.$api.post("/gift-code", giftCodeData, {
					redirect: false
				}).then(res => {
					handleServerResponse(res)

				});

				function handleServerResponse(response) {
					const stripe = Stripe(STRIPE_KEY)
					if (response.error) {

						window.alert(response.error);
						// Show error from server on payment form
					} else if (response.data.error) {

						window.alert(response.data.error);
						vm.processing = false;
						// Show error from server on payment form
					} else if (response.data.requires_action) {
						// Use Stripe.js to handle required card action
						stripe.handleCardAction(
							response.data.payment_intent_client_secret
						).then(function (result) {
							if (result.error) {
								window.alert(result.error.message);
								vm.processing = false;
								// Show error in payment form
							} else {
								// The card action has been handled
								// The PaymentIntent can be confirmed again on the server
							const giftCodeData = { ...vm.gift, id: vm.selected.id };

								vm.$api.post("/gift-code", {
									
									payment_intent_id: result.paymentIntent.id,  giftCodeData,
								}, {
									redirect: false
								}).then(res => {
									return res.data;
								}).then(function (confirmresult) {
									if (confirmresult.success) {
										vm.processing = false;
										vm.giftCode = confirmresult.code;
									} else {
										vm.processing = false;
										window.alert(confirmresult.error);
									}
								})
							}
						});
					} 
					 else {
					vm.processing = false;
					vm.giftCode = response.data.code;

				}
				}
		}


	}
}
</script>

<style lang="less" scoped>
@mobile: ~'(max-width: 1100px)';

section.gift {
	.details-input {
		padding: 10px;
		margin-top: 10px;
		color: #01bad4;
		font-size: 14px;
		border: solid 1px #01bad4;
		border-radius: 7px;
	}
.option{    display: inline-flex;}
	.pill {
		padding: 8px 18px;
		margin-right: 20px;
		color:
			#01bad4;
		text-transform: uppercase;
		font-size: 14px;
		border: solid 1px #01bad4;
		width: 27%;
		display: inline-block;
		margin: 10px;
		border-radius: 100px;
		cursor: pointer;
		text-align: center;

		@media @mobile {
			display: block;
			margin-left: -20px;
			text-align: center;
			width: 100%;

		}

		.email span {
			display: inline-block;

			@media @mobile {
				display: block;


			}
		}

		.email {

			@media @mobile {
				display: block;


			}
		}
	}

	.selected {
		background: #01bad4;
		color: white;
	}
.text{padding: 10px;}
 .input-pound {
     position: relative;
 }
 .input-pound.left input {
     padding-left:20px;
 }
 .input-pound.right input {
     padding-right:20px;
     text-align:end; 
 }

 .input-pound:before {
     position: absolute;
     top: 20px;
     content:"£";
		 color: #01bad4;
 }
 .input-pound.left:before {
     left: 6px;
 }
 .input-pound.right:before {
     right: 6px;
 }
}</style>
